import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import BarcodeReader from "react-barcode-reader";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { v4 as uuid } from "uuid";
import { Link } from "@mui/material";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SettingsIcon from "@mui/icons-material/Settings";
import { Col } from "reactstrap";
import axiosInstance from "Axiosinstance";
import Loader from "components/Loader";
import { NORMAL_RETURN, ONLY_MACID, QR_PRODUCTS, TAGNUMBER_DAMAGED } from "utils/Constant";

function Managereturn() {
  const unique_id = uuid();
  const [fetchloader,setfetchloader] = useState(false)
  const [informationmodal,setinformationmodal] = useState(false)
  const [taginformation,settaginformation] = useState({})
  const [returnlogs, setreturnlogs] = useState([]);
  const [returnform, setreturnform] = useState(false);
  const [macqr2, setmacqr2] = useState(false);
  const [tagqr2, settagqr2] = useState(false);
  const [barqr2, setbarqr2] = useState(false);
  const [macqr, setmacqr] = useState(false);
  const [tagqr, settagqr] = useState(false);
  const [barqr, setbarqr] = useState(false);
  const [formdata, setformdata] = useState({});
  const [returndata, setreturndata] = useState([]);
  const [responsemodal, setresponsemodal] = useState(false);
  const [responseerror, setresponseerror] = useState([]);
  const [editmodal, seteditmodal] = useState(false);
  const [qrerror, setqrerror] = useState(false);
  const [qrerror2, setqrerror2] = useState(false);
  const [width, setWidth] = useState(window?.innerWidth);
  const [settingsmodal, setsettiingsmodal] = useState(false);
  const [returntypefield, setreturntypefiled] = useState([]);
  const [prodcondition, setprodcondition] = useState([]);
  const [addreturntype, setaddreturntype] = useState(false);
  const [fields, setfields] = useState({});
  const [editreturntype, seteditreturntype] = useState(false);
  const [deletemodal, setdeletemodal] = useState(false);
  const [deletefield, setdeletefield] = useState({});
  const [addproductcondition, setaddproductcondition] = useState(false);
  const [editproductcondition, seteditproductcondition] = useState(false);
  const [allcatg, setallcatg] = useState([]);
  const [totalscan, settotalscan] = useState(0);
  const [tagdata, settagdata] = useState([]);
  const [tagno, settagno] = useState("");
  const [macid, setmacid] = useState("");
  const [returncode, setreturncode] = useState("");
  const [loader, setloader] = useState(false);
  const [searchkey, setsearchkey] = useState("");
  const [datefilter, setdatefilter] = useState({
    from: "",
    to: "",
  });

  const [typeofreturn, settypeofreturn] = useState(NORMAL_RETURN)

  const handelradiobutton = (e) => {
   
    settypeofreturn(e.target.value)
  };
  const deletetagdata = (id)=>{
    const updatedItems = tagdata?.filter(item => item?.return_id !== id);
    settagdata(updatedItems)
    settotalscan((prev)=>prev-1)
  }
  const clearValues = () => {
    settotalscan(0);
    setreturnform(false);
    settagno("");
    setmacid("");
    settagdata("");
  };

  //handel tag scanning modal
  const handeltagqr = () => {
    if (typeofreturn === ONLY_MACID || typeofreturn === TAGNUMBER_DAMAGED) {
      setmacqr(true);
    } else {
      settagqr(true);
    }
  };

  useEffect(() => {
    if (!searchkey?.length) {
      fetchAlllogs();
    } else {
      const getsearchResult = setTimeout(() => {
        axiosInstance
          .post("/api/returnscanlogsearch", { key: searchkey })
          .then((data) => {
            setreturnlogs(data?.data);
          })
          .catch((er) => {});
      }, 1000);
      return () => clearTimeout(getsearchResult);
    }
  }, [searchkey]);

  const fetchallReturnfileds = async () => {
    try {
      setloader(true);
      const { data } = await axiosInstance.get(`/api/getreturnfields`);
      //console.log(data);
      setprodcondition(data?.productcondition);
      setreturntypefiled(data?.returntype);
      setloader(false);
    } catch (error) {
      setloader(false);
      //console.log(error);
    }
  };

  const fetchallCategory = async () => {
    try {
      setloader(true);
      const { data } = await axiosInstance.get(`/api/getallcategories`);
      setallcatg(data?.allcategories);
      setloader(false);
    } catch (er) {
      setloader(false);
      //console.log(er);
    }
  };

  const fetchAlllogs = async () => {
    try {
      setloader(true);
      const { data } = await axiosInstance.get(`/api/fetchreturnlogs`);
      setreturnlogs(data?.logs);
      setloader(false);
    } catch (error) {
      setloader(false);
      //console.log(error);
    }
  };

  /*const handeledit = (product) => {
    //console.log(product);
    setformdata(product);
    fetchallCategory();
    fetchallReturnfileds();
    seteditmodal(true);
  };*/

  const handelreturntypeedit = (product) => {
    //console.log(product);
    setfields({ _id: product?._id, returntype: product?.value });
    seteditreturntype(true);
  };

  const handelproductconditionedit = (product) => {
    //console.log(product);
    setfields({ _id: product?._id, productcondition: product?.value });
    seteditproductcondition(true);
  };

  const handeldate = (e) => {
    const datename = e?.target?.name;
    setdatefilter({ ...datefilter, [e?.target?.name]: e?.target?.value });
  };

  const sortdateApi = async (date) => {
    try {
      //console.log(date);
      if (Object.keys(datefilter).length === 2) {
        const { data } = await axiosInstance.post(`/api/sortdate`, {
          datefilter,
          type: "returnscanlogs",
        });
        //console.log(data);
        if (data?.status) {
          setreturnlogs(data?.returlog);
        }
      }
    } catch (error) {}
  };

  //to display all the product condition in the db inside a column displayed on settings modal
  const column3 = [
    {
      dataField: "_id",
      text: "#",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
    },
    {
      dataField: "productcondition",
      text: "productcondition",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product.value ? (
              <Link to="#" className="text-dark">
                {product.value}
              </Link>
            ) : (
              <Link to="#" className="text-dark">
                <p> No </p>
              </Link>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "productcondition",
      text: "Edit",
      formatter: (cellContent, product) => {
        return (
          <div className="d-flex gap-3">
            <>
              <Link
                className="text-success"
                onClick={() => handelproductconditionedit(product)}
                to="#"
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
              </Link>
            </>
          </div>
        );
      },
    },
    {
      dataField: "productcondition",
      text: "Delete",
      formatter: (cellContent, product) => {
        return (
          <div className="d-flex gap-3">
            <>
              <Link
                className="text-danger"
                onClick={() => handeldelete(product)}
                to="#"
              >
                <i className="mdi mdi-delete font-size-18" id="edittooltip"></i>
              </Link>
            </>
          </div>
        );
      },
    },
  ];

  //to display all the return type in the db inside a column displayed on settings modal
  const column2 = [
    {
      dataField: "_id",
      text: "#",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
    },
    {
      dataField: "returntype",
      text: "Return Type",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product.value ? (
              <Link to="#" className="text-dark">
                {product.value}
              </Link>
            ) : (
              <Link to="#" className="text-dark">
                <p> No </p>
              </Link>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "returntype",
      text: "Edit",
      formatter: (cellContent, product) => {
        return (
          <div className="d-flex gap-3">
            <>
              <Link
                className="text-success"
                onClick={() => handelreturntypeedit(product)}
                to="#"
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
              </Link>
            </>
          </div>
        );
      },
    },
    {
      dataField: "returntype",
      text: "Delete",
      formatter: (cellContent, product) => {
        return (
          <div className="d-flex gap-3">
            <>
              <Link
                className="text-danger"
                onClick={() => handeldelete(product)}
                to="#"
              >
                <i className="mdi mdi-delete font-size-18" id="edittooltip"></i>
              </Link>
            </>
          </div>
        );
      },
    },
  ];

  //to display all the result of all return product both sucess and failed (return logs table)
  const column = [
    {
      dataField: "_id",
      text: "",
      formatter: (cell, row, rowIndex) => rowIndex + 1, // Display row number starting from 1
      headerStyle: () => {
        return { width: "50px" }; // Adjust the width as needed
      },
    },
    {
      dataField: "lastscan_date",
      text: "Scanned On",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product.lastscan_date ? (
              <Link to="#" className="text-dark">
                {new Date(product.lastscan_date).toLocaleString()}
              </Link>
            ) : (
              <Link to="#" className="text-dark">
                <p> No </p>
              </Link>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "modified_by",
      text: "Modified By",
      formatter: (cell) => cell || "N/A",
    },
    {
      dataField: "tagnumber",
      text: "TagNumber",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product.tagnumber ? (
              <Link to="#" className="text-dark">
                {product.tagnumber}
              </Link>
            ) : (
              <Link to="#" className="text-dark">
                <p> No </p>
              </Link>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "macid",
      text: "Mac Address",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product.macid ? (
              <Link to="#" className="text-dark">
                {product?.macid}
              </Link>
            ) : (
              <Link to="#" className="text-dark">
                <p> No </p>
              </Link>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "oldcustomername",
      text: "Customername",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product.oldcustomername ? (
              <Link to="#" className="text-dark">
                {product?.oldcustomername}
              </Link>
            ) : (
              <Link to="#" className="text-dark">
                <p> No </p>
              </Link>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "Status",
      text: "Status",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product.Status ? (
              <Link
                to="#"
                style={{
                  color: product?.Status === "Success" ? "green" : "red",
                }}
              >
                {product?.Status}
              </Link>
            ) : (
              <Link to="#" className="text-dark">
                <p> Nill </p>
              </Link>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "message",
      text: "message",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              <Link
                to="#"
                style={{
                  color: product?.Status === "Success" ? "green" : "red",
                }}
              >
                {product?.message}
              </Link>
            </Link>
          </h5>
        );
      },
    },

    {
      /*
      dataField: "status",
      text: "Edit",
      formatter: (cellContent, product) => {
        return (
          <div className="d-flex gap-3">
            {product?.Status === "failed" ? (
              <>
                <Link
                  className="text-success"
                  onClick={() => handeledit(product)}
                  to="#"
                >
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                  ></i>
                </Link>
              </>
            ) : null}
          </div>
        );
      },
      */
    },
  ];

  const fetchtagInformation = async (macid)=>{
    try {
      const {data} = await axiosInstance.get(`/api/getproductbyMacid/${macid}`)
      settaginformation(data?.getTagdetails)
      setfetchloader(false)
      
    } catch (error) {
      setloader(false)
      
    }

  }

  //to handel add and edit of the return fields like procductcondition,return type...etc
  const handelfieldsubmit = async (e, type) => {
    try {
      e.preventDefault();
      //console.log(type);
      setloader(true);
      const { data } = await axiosInstance.post(`/api/addfields`, {
        type,
        fields,
      });
      if (data?.status) {
        setfields({ _id: "", returntype: "", productcondition: "" });
        if (type === "editreturntype") {
          seteditreturntype(false);
        } else if (type === "addreturntype") {
          setaddreturntype(false);
        } else if (type === "addproductcondition") {
          setaddproductcondition(false);
        } else if (type === "editproductcondition") {
          seteditproductcondition(false);
        }

        fetchallReturnfileds();
        setloader(false);
      } else {
        setfields({ _id: "", returntype: "", productcondition: "" });
        setaddreturntype(false);
        setloader(false);
       
      }
    } catch (error) {
      setfields({ returntype: "", productcondition: "" });
      setaddreturntype(false);
      setloader(false);
      alert("intser");
      //console.log(error);
    }
  };

  //to handel the delete operation for the return fields
  const handeldelete = (product) => {
    setdeletemodal(true);
    setdeletefield(product);
  };
  const deleteSubmit = async (e) => {
    try {
      e.preventDefault();
      setloader(true);
      const { data } = await axiosInstance.post(`/api/deletefield`, {
        deletefield,
      });
      if (data?.status) {
        setloader(false);
        setdeletemodal(false);
        setdeletefield({ _id: "" });
        alert("sucess");
        fetchallReturnfileds();
      } else {
        setloader(false);
        setdeletemodal(false);
        setdeletefield({ _id: "" });
        alert("failed");
      }
    } catch (error) {
      //console.log(error);
      setloader(false);
      seteditmodal(false);
      setdeletefield({ _id: "" });
      alert("failed");
    }
  };

  const handelcancel = () => {
    settagno("");
    setreturncode("");
    setmacid("");
    settagqr(false);
    setmacqr(false);
    setbarqr(false);
    setinformationmodal(false)
  };

  const handelcancel2 = () => {
    settagno("");
    setreturncode("");
    setmacid("");
    settagqr2(false);
    setmacqr2(false);
    setbarqr2(false);
  };

  //function to reset some state when we change the product category
  const handelproductCatg = (e) => {
    setskiptagnumber(false);
    settagno("");
    setmacid("");
    setreturncode("");
    setformdata({ ...formdata, [e.target.name]: e.target.value });
  };

  const handelchange = (e) => {
    setformdata({ ...formdata, [e.target.name]: e.target.value });
  };
  const handelfieldchanges = (e) => {
    setfields({ ...fields, [e.target.name]: e.target.value });
  };

 /* const handelscanner = () => {
    setqrerror(false);
    setqrerror2(false);
    settagqr(true);
  };*/

  const tagscan2 = (result) => {
    //console.log(result);
    if (result) {
      if (
        result.includes("https://dashboard.tag8.in/scan/") ||
        result.includes("https://dashboard.tag8.co.in/scan/") ||
        result.includes("https://dashboard.tag8.in/#/scan/")
      ) {
        let tagnumber;
        if (result.includes("https://dashboard.tag8.co.in/scan/")) {
          tagnumber = result.replace("https://dashboard.tag8.co.in/scan/", "");
        } else if (result.includes("https://dashboard.tag8.in/#/scan/")) {
          tagnumber = result.replace("https://dashboard.tag8.in/#/scan/", "");
        } else {
          tagnumber = result.replace("https://dashboard.tag8.in/scan/", "");
        }
        setqrerror(false);

        setformdata({ ...formdata, tagnumber: tagnumber });
        settagqr2(false);
      } else {
        //console.log("reached invalid part");
        //console.log(result.includes("https://dashboard.tag8.in/#/scan/"));
        setqrerror(true);
      }
    }
  };
  const dummpy = () => {
    settagno("TSWHDX4");
    settagqr(false);
    if (
    typeofreturn !== QR_PRODUCTS
    ) {
      setmacqr(true);
    } else {
      setbarqr(true);
    }
  };
  const tagscan = (result) => {
    //console.log(result);
    if (result) {
      if (
        result.includes("https://dashboard.tag8.in/scan/") ||
        result.includes("https://dashboard.tag8.co.in/scan/") ||
        result.includes("https://dashboard.tag8.in/#/scan/") ||
        result.includes("https://genie.tag8.in/#/scan/")
      ) {
        let tagnumber;
        if (result.includes("https://dashboard.tag8.co.in/scan/")) {
          tagnumber = result.replace("https://dashboard.tag8.co.in/scan/", "");
        } else if (result.includes("https://dashboard.tag8.in/#/scan/")) {
          tagnumber = result.replace("https://dashboard.tag8.in/#/scan/", "");
        } else if (result.includes("https://genie.tag8.in/#/scan/")) {
          tagnumber = result.replace("https://genie.tag8.in/#/scan/", "");
        } else {
          tagnumber = result.replace("https://dashboard.tag8.in/scan/", "");
        }
        setqrerror(false);
        //setformdata({ ...formdata, tagnumber: tagnumber });
        settagno(tagnumber);
        settagqr(false);
        if (
       typeofreturn !== QR_PRODUCTS
        ) {
          setmacqr(true);
        } else {
          setbarqr(true);
        }
      } else {
        //console.log("reached invalid part");
        //console.log(result.includes("https://dashboard.tag8.in/#/scan/"));
        setqrerror(true);
      }
    }
  };

  const macscan2 = (result) => {
    if (result) {
      //console.log(result);
      const macAddressPattern = "^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$";
      //console.log(macAddressPattern.test(result.text));
      if (result) {
        if (result.startsWith("http://")) {
          // Remove "http://"
          result.text = result.text.substring(7);
        }
        const mcid = result;
        //console.log(mcid);
        if (mcid.length === 17) {
          setqrerror2(false);
          setformdata({ ...formdata, mcid: result });
          setmacqr2(false);
        } else {
          setqrerror2(true);
        }
      }
      //console.log(result);
    }
  };

  const macscan = (result) => {
    if (result) {
      //console.log(result);
      const macAddressPattern = "^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$";
      //console.log(macAddressPattern.test(result.text));
      if (result) {
        if (result.startsWith("http://")) {
          // Remove "http://"
          result.text = result.text.substring(7);
        }
        const mcid = result;
        //console.log(mcid);
        if (mcid.length === 17) {
         
          setqrerror2(false);
          if(typeofreturn === TAGNUMBER_DAMAGED){
            setfetchloader(true)
            setmacqr(false)
            setinformationmodal(true)
            fetchtagInformation(result)
            return
          }

          setmacqr(false);
          setmacid(result);
          setbarqr(true);
        } else {
          setqrerror2(true);
        }
      }
      //console.log(result);
    }
  };
  const handeldamagedreturn = ()=>{
    settagno(taginformation?.tag_number)
    setmacid(taginformation?.mac_address)
    setinformationmodal(false)
    setbarqr(true);
  }
  const dummac = () => {
    setmacid("D0:4C:4D:F7:F3:D3");
    if(typeofreturn === TAGNUMBER_DAMAGED){
      setfetchloader(true)
      setmacqr(false)
      setinformationmodal(true)
      fetchtagInformation("CD:56:E9:45:5B:28")
      return
    }
    setmacqr(false);
    setbarqr(true);
  };

/*  const handelmacskip = () => {
    setmacid("");
    setmacqr(false);
    setbarqr(true);
  };*/

  const handelskip = () => {
    const tagpair = {
      return_id: unique_id?.slice(0, 8),
      tagnumber: tagno,
      macid: macid || "",
      returncode: "",
      //productcategory: formdata?.productcategory,
      //returntype: formdata?.returntype,
      productcondition: formdata?.productcondition,
      //skiptagnumber,
      typeofreturn
    };
    settagdata((prev) => [...prev, tagpair]);
    settotalscan((prev) => prev + 1);
    handelcancel();
    setbarqr(false);
    if (skiptagnumber) {
      setmacqr(true);
    } else {
      settagqr(true);
    }
  };
  const barscan2 = (result) => {
   
    console.log(result);
    if (result) {
      setformdata({ ...formdata, returncode: result });

      setbarqr2(false);
    }
  };
  const barscan = (result) => {
   
try {
  console.log(result);
  if (result) {
    setformdata({ ...formdata, returncode: result });

    setreturncode(result);
    const tagpair = {
      return_id: unique_id?.slice(0, 8),
      tagnumber: tagno,
      macid: macid,
      returncode: result,
      productcategory: formdata?.productcategory,
      returntype: formdata?.returntype,
      productcondition: formdata?.productcondition,
      typeofreturn
    
    };

    settagdata((prev) => [...prev, tagpair]);
    settotalscan((prev) => prev + 1);
    handelcancel();
    setbarqr(false);
    if (typeofreturn === TAGNUMBER_DAMAGED || typeofreturn === ONLY_MACID) {
      setmacqr(true);
    } else {
      settagqr(true);
    }
  }
  
} catch (error) {
  console.log(error)
  
}
  };
  const handelsearchinput = (e) => {
    setsearchkey(e.target.value);
  };
  const handelsubmit = (e) => {
    e.preventDefault();
    setloader(true);

    //console.log(formdata);

    //const return_id = unique_id.slice(0, 8);
    //const updatedFormdata = { ...formdata, return_id: return_id };

    axiosInstance
      .post(`/api/managereturn`, {
        tagdata: tagdata,
      })
      .then(({ data }) => {
        setloader(false);
        fetchAlllogs();
      })
      .catch((er) => {
        //console.log(er);
      });
    setreturnform(false);
    setformdata({});
    settagdata([]);
    settotalscan(0)
  };
  const handeleditsubmit = (e) => {
    e.preventDefault();

    setloader(true);

    axiosInstance
      .post(`/api/editmanagereturn`, formdata)
      .then(({ data }) => {
        fetchAlllogs();
        setloader(false);
      })
      .catch((er) => {
        //console.log(er);
        setloader(false);
        alert("error happend");
      });

    setformdata({});
    seteditmodal(false);
  };

  const managereturnapi = () => {
    if (returndata.length) {
      axiosInstance
        .post(`/api/managereturn`, returndata)
        .then(({ data }) => {
          setresponseerror(data);
          setresponsemodal(true);
        })
        .catch((er) => {
          //console.log(er);
        });
    }
  };

  const error = (er) => {
    //console.log(er);
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div style={{ marginTop: "2rem" }}>
          <div>
            <h4 style={{ marginTop: "1rem" }}>Manage your Returned Products</h4>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                alignContent: "center",
                marginTop: "2rem",
                justifyContent: "center",
              }}
            >
              <div style={{ marginRight: "5rem" }}>
                <Button
                  onClick={() => {
                    setreturnform(true),
                      fetchallCategory(),
                      fetchallReturnfileds();
                  }}
                  variant="contained"
                >
                  Return Product
                </Button>
                <Button
                  onClick={() => setsettiingsmodal(true)}
                  style={{ margin: "1rem" }}
                  variant="outlined"
                  startIcon={<SettingsIcon />}
                >
                  Settings
                </Button>
              </div>
            </div>

            <>
              <Col style={{ display: "flex" }} xl={6} sm={6}>
                <input
                  type="search"
                  id="form1"
                  placeholder="Search"
                  className="form-control"
                  name="searchkey"
                  value={searchkey}
                  onChange={(e) => handelsearchinput(e)}
                />
              </Col>
              <div style={{ marginTop: "2rem", display: "flex" }}>
                <div style={{ marginLeft: "1rem" }}>
                  <TextField
                    label="Starting Date"
                    variant="outlined"
                    type="date"
                    name="from"
                    onChange={handeldate}
                    value={datefilter?.from || ""}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      max: new Date().toISOString().split("T")[0],
                    }}
                  />
                </div>
                <div style={{ marginLeft: "1rem" }}>
                  <TextField
                    label="Ending Date"
                    variant="outlined"
                    type="date"
                    name="to"
                    onChange={handeldate}
                    value={datefilter?.to || ""}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      max: new Date().toISOString().split("T")[0],
                    }}
                  />
                </div>
                {datefilter?.from && datefilter?.to ? (
                  <div style={{ margin: "1rem" }}>
                    <Button onClick={sortdateApi} variant="outlined">
                      Search
                    </Button>
                  </div>
                ) : null}
              </div>
              <p style={{ fontWeight: "500", marginTop: "3rem" }}>
                Return Products History
              </p>
              <div
                style={{ backgroundColor: "white", marginTop: "2rem" }}
                className="table-responsive"
              >
                <BootstrapTable
                  keyField="history_id"
                  data={returnlogs}
                  columns={column}
                  pagination={paginationFactory()}
                />
              </div>
            </>
          </div>
          <Dialog fullScreen open={editmodal}>
            <DialogTitle>Return Product Details</DialogTitle>
            <DialogContent dividers={scroll === "paper"}>
              <Box
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
              >
                <form onSubmit={(e) => handeleditsubmit(e)}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div>
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          How pairing need to be done
                        </FormLabel>
                        <RadioGroup
                          //onChange={handelradiobutton}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="reportlost_type"
                          //value={skiptagnumber}
                        >
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="QR & Mac ID Pairing"
                          />
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Only Mac ID"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <div>
                      <TextField
                        select
                        id="outlined-multiline-flexible1"
                        label="Return Type"
                        variant="outlined"
                        name="returntype"
                        value={formdata.returntype || ""}
                        onChange={handelchange}
                        required
                      >
                        {returntypefield.map((e) => (
                          <MenuItem key={e?._id} value={e?.value}>
                            {e?.value}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        select
                        id="outlined-multiline-flexible1"
                        label="Product Category"
                        variant="outlined"
                        name="productcategory"
                        value={formdata.productcategory || ""}
                        onChange={handelproductCatg}
                        required
                      >
                        {allcatg?.map((e) => {
                          return (
                            <MenuItem key={e?._id} value={e?.product_category}>
                              {e?.product_category === "Dolphin Bag"
                                ? "Dolphin Bag Tracker"
                                : e?.product_category}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                      <TextField
                        select
                        id="outlined-multiline-flexible1"
                        label="Product Condition"
                        variant="outlined"
                        name="productcondition"
                        value={formdata.productcondition || ""}
                        onChange={handelchange}
                        required
                      >
                        {prodcondition?.map((e) => (
                          <MenuItem key={e?._id} value={e?.value}>
                            {e?.value}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>

                    <div>
                      <TextField
                        onClick={() => {
                          setqrerror(false);
                          settagqr2(true);
                        }}
                        name="tag_number"
                        value={formdata.tagnumber || ""}
                        label="Tagnumber"
                        variant="outlined"
                        required
                      />
                      <TextField
                        onClick={() => {
                          setmacqr2(true);
                          setqrerror2(false);
                        }}
                        label="Macid"
                        value={formdata.mcid || ""}
                        variant="outlined"
                        required={
                          formdata?.productcategory === "Dolphin Bag" ||
                          formdata?.productcategory === "Smart Wallet" ||
                          formdata?.productcategory === "Dolphin Smart Tracker"
                            ? true
                            : false
                        }
                      />
                      <TextField
                        onClick={() => setbarqr2(true)}
                        label="returncode"
                        value={formdata.returncode || ""}
                        variant="outlined"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ margin: "1rem" }}>
                      <Button
                        style={{ margin: "1rem" }}
                        variant="contained"
                        color="error"
                        onClick={() => {
                          seteditmodal(false);
                          setformdata({ tagnumber: "", macid: "" });
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        style={{ margin: "1rem" }}
                        variant="contained"
                        color="success"
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </form>
              </Box>
            </DialogContent>
          </Dialog>

          <Dialog fullScreen open={returnform}>
            <DialogTitle>Return Product Details</DialogTitle>
            <DialogContent dividers={scroll === "paper"}>
              <Box
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
              >
                <form>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p style={{ fontWeight: "600" }}>
                      Total no of scan : {totalscan}
                    </p>

                    <div>
                     
               
                    
                    </div>
                   
                        <div>
                       
                            <div style={{ marginTop: "2rem" }}>
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  How Return need to be done
                                </FormLabel>
                                <RadioGroup
            
                                  onChange={handelradiobutton}
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="reportlost_type"
                                  value={typeofreturn}
                                 
                                >
                                  <FormControlLabel
                                    style={{ marginRight: "4rem" }}
                                    value={NORMAL_RETURN}
          
                                    control={<Radio />}
                                    label="Scan QR & Mac ID"
                                  />
                                  <FormControlLabel
                                  value={ONLY_MACID}
                                  
                                    control={<Radio />}
                                    label="Scan Only Mac ID"
                                  />
                                    <FormControlLabel
                                    value={TAGNUMBER_DAMAGED}
                                  
                                    control={<Radio />}
                                    label="Tagnumber Damaged"
                                  />
                                    <FormControlLabel
                                    value={QR_PRODUCTS}
                                 
                                    control={<Radio />}
                                    label="QR Products"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </div>
                          
                        </div>
                      

               
                      <>
                        <div style={{ margin: "1rem" }}>
                          <Button onClick={handeltagqr} variant="contained">
                            {" "}
                            Start Scaning QR
                          </Button>
                        </div>
                      </>
               
                 
                      <>
                        <div>
                          {(typeofreturn === NORMAL_RETURN || typeofreturn === QR_PRODUCTS) && (
                            <TextField
                              name="tagnumber"
                              value={tagno || ""}
                              label="Tagnumber"
                              variant="outlined"
                              required
                            />
                          )}
                          {typeofreturn !== QR_PRODUCTS &&
                            (
                              <TextField
                                label="Macid"
                                value={macid || ""}
                                variant="outlined"
                               required
                              />
                            )}
                          <TextField
                            label="returncode"
                            value={returncode || ""}
                            variant="outlined"
                          />
                        </div>
                      </>
                
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ margin: "1rem" }}>
                      <Button
                        style={{ margin: "1rem" }}
                        variant="contained"
                        color="error"
                        onClick={clearValues}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
                <div style={{ marginTop: "2rem" }}>
                  {tagdata.length ? (
                    <>
                      <p style={{ fontWeight: "500" }}></p>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell>Tagnumber</TableCell>
                              <TableCell>Macaddress</TableCell>
                              <TableCell>Returncode</TableCell>
                              <TableCell>Delete</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {tagdata.map((e, index) => {
                              return (
                                <TableRow key={e?.return_id}>
                                  <TableCell>{index + 1}</TableCell>
                                  <TableCell>{e.tagnumber}</TableCell>
                                  <TableCell>{e.macid}</TableCell>
                                  <TableCell>{e.returncode}</TableCell>

                                  <TableCell onClick={()=>deletetagdata(e?.return_id)}><i   className="mdi mdi-delete font-size-18 text-danger"
            style={{ cursor: "pointer" }}
            id="edittooltip"></i></TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Button
                        onClick={handelsubmit}
                        style={{ margin: "2rem", alignItems: "center" }}
                        variant="contained"
                      >
                        Submit
                      </Button>
                    </>
                  ) : null}
                </div>
              </Box>
            </DialogContent>
          </Dialog>
          <Dialog fullWidth={"xs"} maxWidth={"xs"} open={tagqr2}>
            <DialogTitle style={{ textAlign: "center", fontWeight: "bold" }}>
              Scan TagNumber
            </DialogTitle>
            <p style={{ textAlign: "center", fontWeight: "500" }}>
              Please scan your TagNumber QR{" "}
            </p>
            <small style={{ textAlign: "center" }}>Using your QR Gun</small>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <BarcodeReader
                onScan={tagscan2}
                onError={error}
                style={{
                  width: "50%",
                  marginLeft: "4rem",
                  marginBottom: "2rem",
                }}
              />
            </div>

            {qrerror ? (
              <p
                style={{
                  textAlign: "center",
                  color: "red",
                  marginTop: "2rem",
                }}
              >
                Invalid QR
              </p>
            ) : null}
            <DialogActions>
              <Button onClick={handelcancel2}>Close</Button>
            </DialogActions>
          </Dialog>
          <Dialog fullWidth={"xs"} maxWidth={"xs"} open={macqr2}>
            <DialogTitle style={{ textAlign: "center", fontWeight: "bold" }}>
              Scan MacAddress
            </DialogTitle>
            <p style={{ textAlign: "center", fontWeight: "500" }}>
              Please scan your MacAddress QR{" "}
            </p>
            <small style={{ textAlign: "center" }}>Using your QR Gun</small>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <BarcodeReader
                onScan={macscan2}
                onError={error}
                style={{
                  width: "50%",
                  marginLeft: "4rem",
                  marginBottom: "2rem",
                }}
              />
            </div>
            {qrerror2 ? (
              <p
                style={{
                  textAlign: "center",
                  color: "red",
                  marginTop: "2rem",
                }}
              >
                Invalid QR
              </p>
            ) : null}
            <DialogActions>
              <Button onClick={handelcancel2}>Close</Button>
            </DialogActions>
          </Dialog>
          <Dialog fullWidth={"xs"} maxWidth={"xs"} open={barqr2}>
            <DialogTitle style={{ textAlign: "center", fontWeight: "bold" }}>
              Scan Returncode
            </DialogTitle>
            <p style={{ textAlign: "center", fontWeight: "500" }}>
              Please scan your Return Barcodekkk{" "}
            </p>
            <small style={{ textAlign: "center" }}>
              Using your barcode reader
            </small>
            <BarcodeReader
              onScan={barscan2}
              style={{ width: "50%", marginLeft: "4rem", marginBottom: "2rem" }}
            />

            <DialogActions>
              <Button onClick={handelcancel2}>Close</Button>
            </DialogActions>
          </Dialog>
          <Dialog fullWidth={"xs"} maxWidth={"xs"} open={tagqr}>
            <DialogTitle style={{ textAlign: "center", fontWeight: "bold" }}>
              Scan TagNumber
            </DialogTitle>
            <p style={{ textAlign: "center", fontWeight: "500" }}>
              Please scan your TagNumber QR{" "}
            </p>
            <small style={{ textAlign: "center" }}>Using your QR Gun</small>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p>Total no of scan : {totalscan}</p>
              <BarcodeReader
                onScan={tagscan}
                onError={error}
                style={{
                  width: "50%",
                  marginLeft: "4rem",
                  marginBottom: "2rem",
                }}
              />
            </div>

            {qrerror ? (
              <p
                style={{
                  textAlign: "center",
                  color: "red",
                  marginTop: "2rem",
                }}
              >
                Invalid QR
              </p>
            ) : null}
            <DialogActions>
              <Button onClick={handelcancel}>Close</Button>
            
            
             
            </DialogActions>
          </Dialog>

          <Dialog fullWidth={"xs"} maxWidth={"xs"} open={macqr}>
            <DialogTitle style={{ textAlign: "center", fontWeight: "bold" }}>
              Scan MacAddress
            </DialogTitle>
            <p style={{ textAlign: "center", fontWeight: "500" }}>
              Please scan your MacAddress QR{" "}
            </p>
            <small style={{ textAlign: "center" }}>Using your QR Gun</small>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p>Total no of scan : {totalscan}</p>
              <BarcodeReader
                onScan={macscan}
                onError={error}
                style={{
                  width: "50%",
                  marginLeft: "4rem",
                  marginBottom: "2rem",
                }}
              />
            </div>
            {qrerror2 ? (
              <p
                style={{
                  textAlign: "center",
                  color: "red",
                  marginTop: "2rem",
                }}
              >
                Invalid QR
              </p>
            ) : null}
            <DialogActions>
              <Button onClick={handelcancel}>Close</Button>
         
            
            </DialogActions>
          </Dialog>

          <Dialog fullWidth={"xs"} maxWidth={"xs"} open={barqr}>
            <DialogTitle style={{ textAlign: "center", fontWeight: "bold" }}>
              Scan Returncode
            </DialogTitle>
            <p style={{ textAlign: "center", fontWeight: "500" }}>
              Please scan your Return Barcode{" "}
            </p>
            <small style={{ textAlign: "center" }}>
              Using your barcode reader
            </small>
            <BarcodeReader
              onScan={barscan}
              style={{ width: "50%", marginLeft: "4rem", marginBottom: "2rem" }}
            />

            <DialogActions>
              <Button onClick={handelskip}>Skip</Button>
            </DialogActions>
          </Dialog>

          <Dialog maxWidth={"lg"} open={responsemodal}>
            <DialogTitle>Manage Return</DialogTitle>
            <DialogContent>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Tagnumber</TableCell>
                      <TableCell>status</TableCell>
                      <TableCell>message</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {responseerror.map((e) => {
                      return (
                        <TableRow key={e.id}>
                          <TableCell>{e.tagnumber}</TableCell>
                          <TableCell
                            style={{
                              color: e.status === "Failed" ? "red" : "green",
                            }}
                            className="text-red"
                          >
                            {e.status}
                          </TableCell>
                          <TableCell
                            style={{
                              color: e.status === "Failed" ? "red" : "green",
                            }}
                          >
                            {e.message}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <DialogActions>
                <Button onClick={() => setresponsemodal(false)}>Cancel</Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
          <Dialog open={informationmodal}>
              <DialogTitle>Tag Information</DialogTitle>
              <DialogContent>
          {
            fetchloader ? <h1>Fetching Tag Information ....</h1> : <>
          {
            taginformation ?       <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Tagnumber</TableCell>
                  <TableCell>Macaddress</TableCell>
                  <TableCell>Product Category</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
             
                    <TableRow key={taginformation?._id}>
                    
                      <TableCell>{taginformation?.tag_number || "No Tagnumber Found"}</TableCell>
                      <TableCell>{taginformation?.mac_address || "No Mac address Found"}</TableCell>
                      <TableCell>{taginformation?.product_category || "No Product Category Found"}</TableCell>
                    </TableRow>
                  
              
              </TableBody>
            </Table>
          </TableContainer> : 
          <h1>There Is No Data For The Given Mac Address</h1>
          }
            </>
          }
              </DialogContent>
              <DialogActions>
                <Button
             
                onClick={handelcancel}
                
                 
                >
                  Cancel
                </Button>
                {
                  taginformation && 
                  <Button onClick={handeldamagedreturn}>Return</Button>
                }
              
              </DialogActions>
            </Dialog>

          <Dialog fullScreen open={settingsmodal}>
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => setsettiingsmodal(false)}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                >
                  Settings
                </Typography>
                <Button
                  autoFocus
                  color="inherit"
                  onClick={() => setsettiingsmodal(false)}
                >
                  close
                </Button>
              </Toolbar>
            </AppBar>
            <DialogContent dividers={scroll === "paper"}>
              <Box
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p style={{ fontWeight: "500", marginTop: "3rem" }}>
                    Return type
                  </p>
                  <div
                    style={{
                      backgroundColor: "white",
                      marginTop: "2rem",
                      width: "50%",
                    }}
                    className="table-responsive"
                  >
                    <Button
                      style={{ margin: "1rem" }}
                      variant="contained"
                      onClick={() => setaddreturntype(true)}
                    >
                      Add Return Type
                    </Button>
                    <BootstrapTable
                      keyField="_id"
                      data={returntypefield}
                      columns={column2}
                      pagination={paginationFactory()}
                    />
                  </div>
                  <p style={{ fontWeight: "500", marginTop: "3rem" }}>
                    Product condition
                  </p>
                  <div
                    style={{
                      backgroundColor: "white",
                      marginTop: "2rem",
                      width: "50%",
                    }}
                    className="table-responsive"
                  >
                    <Button
                      onClick={() => setaddproductcondition(true)}
                      style={{ margin: "1rem" }}
                      variant="contained"
                    >
                      Add Product condition
                    </Button>
                    <BootstrapTable
                      keyField="_id"
                      data={prodcondition}
                      columns={column3}
                      pagination={paginationFactory()}
                    />
                  </div>
                </div>
              </Box>

              <Dialog open={addreturntype}>
                <DialogTitle>Add Return Type</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    <form
                      style={{ padding: "1rem" }}
                      onSubmit={(e) => handelfieldsubmit(e, "addreturntype")}
                    >
                      <div>
                        <TextField
                          name="returntype"
                          value={fields.returntype || ""}
                          onChange={handelfieldchanges}
                          fullWidth
                          label="Return Type"
                          id="fullWidth"
                        />
                      </div>

                      <div style={{ margin: "1rem" }}>
                        <Button
                          style={{ margin: "1rem" }}
                          variant="contained"
                          color="error"
                          onClick={() => {
                            setaddreturntype(false);
                            setfields({ returntype: "", productcondition: "" });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          style={{ margin: "1rem" }}
                          variant="contained"
                          color="success"
                        >
                          Add
                        </Button>
                      </div>
                    </form>
                  </DialogContentText>
                </DialogContent>
              </Dialog>
              <Dialog open={editreturntype}>
                <DialogTitle>Edit Return Type</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    <form
                      style={{ padding: "1rem" }}
                      onSubmit={(e) => handelfieldsubmit(e, "editreturntype")}
                    >
                      <div>
                        <TextField
                          name="returntype"
                          value={fields.returntype || ""}
                          onChange={handelfieldchanges}
                          fullWidth
                          label="Return Type"
                          id="fullWidth"
                        />
                      </div>

                      <div style={{ margin: "1rem" }}>
                        <Button
                          style={{ margin: "1rem" }}
                          variant="contained"
                          color="error"
                          onClick={() => {
                            seteditreturntype(false);
                            setfields({ returntype: "", productcondition: "" });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          style={{ margin: "1rem" }}
                          variant="contained"
                          color="success"
                        >
                          Add
                        </Button>
                      </div>
                    </form>
                  </DialogContentText>
                </DialogContent>
              </Dialog>

              <Dialog open={addproductcondition}>
                <DialogTitle>Add Product Condition</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    <form
                      style={{ padding: "1rem" }}
                      onSubmit={(e) =>
                        handelfieldsubmit(e, "addproductcondition")
                      }
                    >
                      <div>
                        <TextField
                          name="productcondition"
                          value={fields.productcondition || ""}
                          onChange={handelfieldchanges}
                          fullWidth
                          label="productcondition"
                          id="fullWidth"
                        />
                      </div>

                      <div style={{ margin: "1rem" }}>
                        <Button
                          style={{ margin: "1rem" }}
                          variant="contained"
                          color="error"
                          onClick={() => {
                            setaddproductcondition(false);
                            setfields({
                              _id: "",
                              returntype: "",
                              productcondition: "",
                            });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          style={{ margin: "1rem" }}
                          variant="contained"
                          color="success"
                        >
                          Add
                        </Button>
                      </div>
                    </form>
                  </DialogContentText>
                </DialogContent>
              </Dialog>

              <Dialog open={editproductcondition}>
                <DialogTitle>Edit Product Condition</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    <form
                      style={{ padding: "1rem" }}
                      onSubmit={(e) =>
                        handelfieldsubmit(e, "editproductcondition")
                      }
                    >
                      <div>
                        <TextField
                          name="productcondition"
                          value={fields.productcondition || ""}
                          onChange={handelfieldchanges}
                          fullWidth
                          label="productcondition"
                          id="fullWidth"
                        />
                      </div>

                      <div style={{ margin: "1rem" }}>
                        <Button
                          style={{ margin: "1rem" }}
                          variant="contained"
                          color="error"
                          onClick={() => {
                            seteditproductcondition(false);
                            setfields({
                              _id: "",
                              returntype: "",
                              productcondition: "",
                            });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          style={{ margin: "1rem" }}
                          variant="contained"
                          color="success"
                        >
                          update
                        </Button>
                      </div>
                    </form>
                  </DialogContentText>
                </DialogContent>
              </Dialog>
            </DialogContent>
            <Dialog open={deletemodal}>
              <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete this field..?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setdeletemodal(false);
                    setdeletefield({ _id: "" });
                  }}
                >
                  Disagree
                </Button>
                <Button onClick={deleteSubmit}>Agree</Button>
              </DialogActions>
            </Dialog>

          
    
          </Dialog>
        </div>
      )}
    </>
  );
}

export default Managereturn;
