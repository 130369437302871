import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import classnames from "classnames";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  NavItem,
  NavLink,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
  UncontrolledTooltip,
} from "reactstrap";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import BootstrapTable from "react-bootstrap-table-next";

import images from "assets/images";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from "axios";
const { ExportCSVButton } = CSVExport;
import MiniWidget from "../Dashboard/mini-widget";
import { BASE_URL } from "../../config";
const FormData = require("form-data");

import {
  getUsers,
  addNewUser,
  updateUser,
  deleteUser,
} from "store/contacts/actions";

import { isEmpty, size, map } from "lodash";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import Switch from "react-switch";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { TailSpin } from "react-loader-spinner";
import axiosInstance from "Axiosinstance";


const loaderContainerStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 9999,
};
const loaderStyle = {
  width: "100px",
  height: "100px",
};

class TagsList extends Component {
  // Dolphin Bag,
  // Dolphin Camera Kit,
  // Dolphin Smart Tracker,
  // Smart Wallet

  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      users: [],
      searchkey: "",
      user: "",
      dbLink: null,
      tagUsername: null,
      prdtct: "Tag",
      selectedTag: null,
      selecteddelTag: null,
      productCategory: "Generic",
      modal: false,
      duplicateModal: false,
      userInfoModal: false,

      dateRange: false,
      viewActive: "",
      showLoader: false,
      qrmodal: false,
      qrvalue: "",
      userDetails: null,
      loading: false,
      reports: [
        // {
        //   icon: "bx bx-copy-alt",
        //   title: "Orders",
        //   value: "1,452",
        //   badgeValue: "+ 0.2%",
        //   color: "success",
        //   desc: "From previous period",
        // },
        // {
        //   icon: "bx bx-archive-in",
        //   title: "Revenue",
        //   value: "$ 28,452",
        //   badgeValue: "+ 0.2%",
        //   color: "success",
        //   desc: "From previous period",
        // },
        // {
        //   icon: "bx bx-purchase-tag-alt",
        //   title: "Average Price",
        //   value: "$ 16.2",
        //   badgeValue: "0%",
        //   color: "warning",
        //   desc: "From previous period",
        // },
      ],
      deleteModal: false,

      columnsGenlfTag: [
        {
          dataField: "generate_tag_id",
          text: "id",
          formatter: (cellContent, user) => <>{user.generate_tag_id}</>,
        },

        {
          dataField: "tag_number",
          text: "Tag Number",
          // formatter: (cellContent, user) => <>{user.tag_number}</>,
        },
        {
          dataField: "tag_code",
          text: "Tag Iinital",
        },

        {
          dataField: "status",
          text: "Status",
        },
        {
          dataField: "create_date",
          text: "Created Date",
          // formatter: (cellContent, user) => <>{new Date(user.create_date).toLocaleDateString()}</>,
          formatter: (cellContent, user) => (
            <>
              <h5 className="font-size-14 mb-1">
                {user.create_date != null ? (
                  <Link to="#" className="text-dark">
                    {new Date(user.create_date).toLocaleDateString()}
                  </Link>
                ) : (
                  <Link to="#" className="text-dark">
                    {new Date(user.date_of_purchase).toLocaleDateString()}
                  </Link>
                )}
              </h5>
            </>
          ),
        },
        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, user) => (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
              </Link>
              {user.tag_status === "active" && user.tag_number ? (
                <Link className="text-info" to="#">
                  <i
                    className="mdi mdi-information font-size-18"
                    id="edittooltip"
                    onClick={() => {
                      this.toggleUserInfoModal(); // open modal
                      this.fetchUserDetailsByTagnumber(user);
                    }}
                  ></i>
                </Link>
              ) : null}
              <Link className="text-failure" to="#">
                <i
                  className="mdi mdi-trash-can font-size-18"
                  id="edittooltip"
                ></i>
              </Link>
            </div>
          ),
        },
      ],

      columnsGenTag: [
        {
          dataField: "tag_number",
          text: "Tag Number",
          // formatter: (cellContent, user) => <>{user.tag_number}</>,
        },
        {
          dataField: "tag_initial",
          text: "Tag Iinital",
          //  formatter: (cellContent, user) => <>{user.tag_number}</>,
        },
        {
          dataField: "product_category",
          text: "Type",
          formatter: (cellContent, user) => (
            <>
              <h5 className="font-size-14 mb-1">
                {user.product_category != null ? (
                  <Link to="#" className="text-dark">
                    {user.product_category
                      ? user.product_category
                      : this.state.prdtct}
                  </Link>
                ) : (
                  <Link to="#" className="text-dark">
                    <p> {this.state.prdtct} </p>
                  </Link>
                )}
              </h5>
              {/* <p className="text-muted mb-0">{user.designation}</p> */}
            </>
          ),
        },
        {
          dataField: "tag_status",
          text: "Status",
        },
        {
          dataField: "Mac Address",
          text: "Mac address",
          formatter: (cellContent, user) => (
            <>
              <h5 className="font-size-14 mb-1">
                {user?.mac_address != null ? (
                  <Link to="#" className="text-dark">
                    {user.mac_address ? user.mac_address : "nill"}
                  </Link>
                ) : (
                  <Link to="#" className="text-dark">
                    <p>null</p>
                  </Link>
                )}
              </h5>
              {/* <p className="text-muted mb-0">{user.designation}</p> */}
            </>
          ),
        },
        {
          dataField: "create_date",
          text: "Created Date",
          // formatter: (cellContent, user) => <>{new Date(user.create_date).toLocaleDateString()}</>,
          formatter: (cellContent, user) => (
            <>
              <h5 className="font-size-14 mb-1">
                {user.create_date != null ? (
                  <Link to="#" className="text-dark">
                    {new Date(user.create_date).toLocaleDateString()}
                  </Link>
                ) : (
                  <Link to="#" className="text-dark">
                    {new Date(user.date_of_purchase).toLocaleDateString()}
                  </Link>
                )}
              </h5>
            </>
          ),
        },
        {
          dataField: "activation_date",
          text: "Activation Date",
          formatter: (cellContent, user) => (
            <>
              <h5 className="font-size-14 mb-1">
                {user.activation_date != null ? (
                  <Link to="#" className="text-dark">
                    {new Date(user.activation_date).toLocaleDateString()}
                  </Link>
                ) : (
                  <Link to="#" className="text-dark">
                    <p> NA </p>
                  </Link>
                )}
              </h5>
            </>
          ),
        },
        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, user) => (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => this.editSingleTag(user)}
                ></i>
              </Link>
              {user.tag_status === "active" && user.tag_number ? (
                <Link className="text-info" to="#">
                  <i
                    className="mdi mdi-information font-size-18"
                    id="edittooltip"
                    onClick={() => {
                      this.toggleUserInfoModal(); // open modal
                      this.fetchUserDetailsByTagnumber(user);
                    }}
                  ></i>
                </Link>
              ) : null}
              <Link className="text-failure" to="#">
                <i
                  className="mdi mdi-trash-can font-size-18"
                  id="edittooltip"
                  onClick={() => {
                    this.tog_standard();
                    this.setSelctedDel(user);
                  }}
                ></i>
              </Link>
            </div>
          ),
        },
      ],

      columnsDupTag: [
        {
          dataField: "tag_number",
          text: "Tag Number",
          // formatter: (cellContent, user) => <>{user.tag_number}</>,
        },
        {
          dataField: "tag_initial",
          text: "Tag Iinital",
          //  formatter: (cellContent, user) => <>{user.tag_number}</>,
        },
        {
          dataField: "product_category",
          text: "Type",
          formatter: (cellContent, user) => (
            <>
              <h5 className="font-size-14 mb-1">
                {user.product_category != null ? (
                  <Link to="#" className="text-dark">
                    {user.product_category
                      ? user.product_category
                      : this.state.prdtct}
                  </Link>
                ) : (
                  <Link to="#" className="text-dark">
                    <p> {this.state.prdtct} </p>
                  </Link>
                )}
              </h5>
              {/* <p className="text-muted mb-0">{user.designation}</p> */}
            </>
          ),
        },
        {
          dataField: "tag_status",
          text: "Status",
        },
        {
          dataField: "create_date",
          text: "Created Date",
          // formatter: (cellContent, user) => <>{new Date(user.create_date).toLocaleDateString()}</>,
          formatter: (cellContent, user) => (
            <>
              <h5 className="font-size-14 mb-1">
                {user.create_date != null ? (
                  <Link to="#" className="text-dark">
                    {new Date(user.create_date).toLocaleDateString()}
                  </Link>
                ) : (
                  <Link to="#" className="text-dark">
                    {new Date(user.date_of_purchase).toLocaleDateString()}
                  </Link>
                )}
              </h5>
            </>
          ),
        },
        {
          dataField: "activation_date",
          text: "Activation Date",
          formatter: (cellContent, user) => (
            <>
              <h5 className="font-size-14 mb-1">
                {user.activation_date != null ? (
                  <Link to="#" className="text-dark">
                    {new Date(user.activation_date).toLocaleDateString()}
                  </Link>
                ) : (
                  <Link to="#" className="text-dark">
                    <p> NA </p>
                  </Link>
                )}
              </h5>
            </>
          ),
        },
      ],
      genTag: [],
      dupTag: [],

      columns: [
        {
          dataField: "idno",
          text: "Id No",
        },
        {
          dataField: "pdate",
          text: "Date",
        },
        {
          dataField: "type",
          text: "Type",
        },
        {
          dataField: "coin",
          text: "Currency",
        },
        {
          dataField: "amount",
          text: "Amount",
        },
        {
          dataField: "valueInUsd",
          text: "value in USD",
        },
      ],
      selectedRange: [],
      activeTbl: 9,
      modal_standard: false,
    };
    this.handleUserClick = this.handleUserClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleUserInfoModal = this.toggleUserInfoModal.bind(this);
    this.toggleDup = this.toggleDup.bind(this);
    this.handleUserClicks = this.handleUserClicks.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);

    this.editSingleTag = this.editSingleTag.bind(this);
    this.tog_standard = this.tog_standard.bind(this);
    this.generateqrApi = this.generateqrApi.bind(this);
    this.generateerror = this.generateerror.bind(this);
    this.generateSuccess = this.generateSuccess.bind(this);
    this.errorHandeling = this.errorHandeling.bind(this);
    // this.deleteTag = this.deleteTag.bind(this);
  }
  generateerror = (err) => {
    toast.error(err, {
      position: "top-center",
    });
  };
  generateSuccess = (err) => {
    toast.success(err, {
      position: "top-center",
    });
  };

  // function to handel the error and dispaly a alert
  errorHandeling = (er) => {
    if (er?.response?.data?.authError) {
      this.generateerror(
        er?.response?.data?.msg?.message ||
          er?.response?.data?.msg ||
          "unexpected error ocured"
      );
    } else {
      this.generateerror(
        er?.response?.data?.message || "unexpected error ocured"
      );
    }
  };

  async generateqrApi() {
    try {
      if (this?.state?.qrvalue?.length) {
        const { data } = await axiosInstance.post(`/api/generateqr`, {
          value: this?.state?.qrvalue,
        });
        // Create a link element for download
        const link = document.createElement("a");
        link.href = data?.qrcodeimg;
        link.download = `qrcode${Date.now()}.png`;
        // Simulate a click on the link to trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up: remove the link element
        document.body.removeChild(link);
        this.setState({ qrvalue: "" });
        this.generateSuccess("QR Generated");
      }
    } catch (er) {
      //console.log(er);
      this.generateerror("QR Generation Failed");
    }
  }

  componentDidMount() {
    //setTimeout(function () {
    // document.getElementById("preloader").style.display = "none";
    // document.getElementById("status").style.display = "none";
    //}, 2000);
    this.fetchGenTgDt(
      this.state.prdtct,
      this.state.productCategory,
      this.state.viewActive
    );
  }

  //deleteTag

  deleteTag() {
    //console.log("Call the Delete Tag API", this.state.selecteddelTag);
    let data = JSON.stringify({
      isDeleted: true,
      tag_number: this.state.selecteddelTag.tag_number,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/api/deleteTagbyTgNum`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axiosInstance
      .request(config)
      .then((response) => {
        //console.log(JSON.stringify(response.data));
        if (response.data != null) {
          toast.success("Tag Details Deleted successfully");
          this.fetchGenTgDt(
            this.state.prdtct,
            this.state.productCategory,
            this.state.viewActive
          );
          this.tog_standard();
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  }

  setSelctedDel(tagData) {
    this.setState({ selecteddelTag: tagData });
  }

  tog_standard() {
    //console.log(this.modal_standard)

    // console.log(this.state.modal_standard);
    if (this.state.modal_standard) {
      this.setState({ selecteddelTag: null });
    }

    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    //this.setSelctedDel();
    this.removeBodyCss();
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  fetchGenTgDt(categoryName, productCategory, viewActive) {
    this.setState({ loading: true });
    let config = {
      method: "get",
      url: `/api/getTagsListByCat?modelName=${categoryName}&product_category=${productCategory}&limit=150&tag_status=${viewActive}`,
      headers: {
        Authorization: JSON.parse(localStorage.getItem("authUser")).token,
      },
    };
    axiosInstance
      .request(config)
      .then((response) => {
        //console.log(response.data, "dtattete");
        const data = response.data.data;
        const tagsData = data || [];

        // Calculate the count of active and inactive tags
        const activeCount = tagsData.filter(
          (tag) => tag.tag_status === "active"
        ).length;
        const inactiveCount = tagsData.filter(
          (tag) => tag.tag_status === "inactive"
        ).length;
        // this.setState({
        //   genTag:
        //     response.data.count != undefined ? response.data.data.data : [],
        //   dateRange: false,
        // });
        this.setState((prevState) => {
          // Filter out existing reports related to "Active Tags" and "Inactive Tags"
          const updatedReports = prevState.reports.filter(
            (report) =>
              report.title !== "Active Tags" && report.title !== "Inactive Tags"
          );
          //console.log(activeCount, "actuve");
          // Add new reports for active and inactive tags based on the current data
          updatedReports.push(
            {
              icon: "bx bx-check",
              title: "Active Tags",
              value: activeCount.toString(),
              badgeValue: "+0%",
              color: "success",
              desc: "Number of active tags",
            },
            {
              icon: "bx bx-x",
              title: "Inactive Tags",
              value: inactiveCount.toString(),
              badgeValue: "+0%",
              color: "danger",
              desc: "Number of inactive tags",
            }
          );

          return {
            genTag: response.data.count !== undefined ? response.data.data : [],
            dateRange: false,
            reports: updatedReports,
            loading: false,
          };
        });
      })
      .catch((error) => {
        //console.log(error);
      });
  }

  exlfetchGenTgDt(categoryName, productCategory, viewActive) {
    if (this.state.prdtct === "L&F") {
      if (this.state.selectedRange.length === 2) {
        let fromDate = new Date(this.state.selectedRange[0])
          .toISOString()
          .split("T")[0];
        let toDate = new Date(this.state.selectedRange[1])
          .toISOString()
          .split("T")[0];
        if (this.state.prdtct === "L&F") {
          let config = {
            method: "get",
            url: `/api/exportExcelLFListByCat?startDate=${fromDate}&endDate=${toDate}`,
            headers: {
              Authorization: JSON.parse(localStorage.getItem("authUser")).token,
            },
          };
          axiosInstance
            .request(config)
            .then((response) => {
              this.dowloadfromLink(response.data.filepath);
            })
            .catch((error) => {
              //console.log(error);
            });
        } else {
          let config = {
            method: "get",
            url: `/api/exportExcelLFListByCat?limit=450&searchVal=No Search`,
            headers: {
              Authorization: JSON.parse(localStorage.getItem("authUser")).token,
            },
          };
          axiosInstance
            .request(config)
            .then((response) => {
              this.dowloadfromLink(response.data.filepath);
            })
            .catch((error) => {
              //console.log(error);
            });
        }
      }
    } else {
      let config = {
        method: "get",
        url: `/api/exportExcelListByCat?modelName=${categoryName}&product_category=${productCategory}&limit=150&tag_status=${viewActive}`,
        headers: {
          Authorization: JSON.parse(localStorage.getItem("authUser")).token,
        },
      };
      axiosInstance
        .request(config)
        .then((response) => {
          this.dowloadfromLink(response.data.filepath);
        })
        .catch((error) => {
          //console.log(error);
        });
    }
  }

  fetchBasedOnDtRange() {
    if (this.state.selectedRange.length === 2) {
      let fromDate = new Date(this.state.selectedRange[0])
        .toISOString()
        .split("T")[0];
      let toDate = new Date(this.state.selectedRange[1])
        .toISOString()
        .split("T")[0];
      if (this.state.prdtct === "L&F") {
        let config = {
          method: "get",
          url: `/api/fetchAllLfTags?startDate=${fromDate}&endDate=${toDate}`,
          headers: {
            Authorization: JSON.parse(localStorage.getItem("authUser")).token,
          },
        };
        axiosInstance
          .request(config)
          .then((response) => {
            this.setState({
              genTag:
                response.data.genTags.length > 0 ? response.data.genTags : [],
              dateRange: true,
            });
          })
          .catch((error) => {
            setTimeout(function () {
              document.getElementById("preloader").style.display = "none";
              document.getElementById("status").style.display = "none";
            }, 2000);
            //console.log(error);
          });
      } else {
        let config = {
          method: "get",
          url: `/api/getTagsListByCat?modelName=${this.state.prdtct}&product_category=${this.state.productCategory}&limit=150&create_date[$gte]=${fromDate}&create_date[$lt]=${toDate}`,
          headers: {
            Authorization: JSON.parse(localStorage.getItem("authUser")).token,
          },
        };
        axiosInstance
          .request(config)
          .then((response) => {
            //console.log("Date Range Response");
            this.setState({
              genTag:
                response.data.count != undefined ? response.data.data.data : [],
              dateRange: true,
            });
          })
          .catch((error) => {
            //console.log(error);
          });
      }
    }
  }

  fetchLFtags() {
    let config = {
      method: "get",
      url: `/api/fetchAllLfTags?limit=450&searchVal=No Search`,
      headers: {
        Authorization: JSON.parse(localStorage.getItem("authUser")).token,
      },
    };
    axiosInstance
      .request(config)
      .then((response) => {
        //console.log(response);
        this.setState({
          genTag: response.data.genTags.length > 0 ? response.data.genTags : [],
        });
      })
      .catch((error) => {
        //console.log(error);
      });
  }

  fetchSrchLFtags(searchVal) {
    let config = {
      method: "get",
      url: `/api/fetchAllLfTags?searchVal=${searchVal}`,
      headers: {
        Authorization: JSON.parse(localStorage.getItem("authUser")).token,
      },
    };
    axiosInstance
      .request(config)
      .then((response) => {
        //console.log(response);
        this.setState({
          genTag: response.data.genTags.length > 0 ? response.data.genTags : [],
        });
      })
      .catch((error) => {
        //console.log(error);
      });
  }

  resetSrch(prdCat, productCategory) {
    let config = {
      method: "get",
      url: `/api/getTagsListByCat?modelName=${prdCat}&product_category=${productCategory}&limit=150`,
      headers: {
        Authorization: JSON.parse(localStorage.getItem("authUser")).token,
      },
    };
    axiosInstance
      .request(config)
      .then((response) => {
        //console.log("response.data.data--->", response.data.data);
        this.setState({ genTag: response.data.data });
      })
      .catch((error) => {
        //console.log(error);
      });
  }

  searchVal(prdCat, productCategory, srchKey) {
    let config = {
      method: "get",
      url: `/api/getTagsListByCat?modelName=${prdCat}&product_category=${productCategory}&searchValue=${srchKey}`,
      headers: {
        Authorization: JSON.parse(localStorage.getItem("authUser")).token,
      },
    };
    axiosInstance
      .request(config)
      .then((response) => {
        //console.log("response.data.data--->", response.data.data);
        this.setState({ genTag: response.data.data });
      })
      .catch((error) => {
        //console.log(error);
      });
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }
  toggleUserInfoModal() {
    this.setState((prevState) => ({
      userInfoModal: !prevState.userInfoModal,
      userDetails: null,
    }));
  }

  toggleDup() {
    this.setState((prevState) => ({
      duplicateModal: !prevState.duplicateModal,
    }));
  }

  handleUserClicks = () => {
    this.setState({ user: "", isEdit: false });
    this.toggle();
  };

  displayActv(check) {
    if (this.state.prdtct === "L&F") {
      if (check) {
        let lftgs = [...this.state.genTag];
        lftgs = lftgs.filter((el) => el.status === "A");
        this.setState({ genTag: lftgs, viewActive: "active" });
      } else {
        this.setState({ viewActive: "" });
        if (this.state.selectedRange.length > 0) {
          this.fetchBasedOnDtRange();
        } else {
          this.fetchLFtags();
        }
      }
    } else {
      if (check) {
        this.setState({ viewActive: "active" }, () => {
          this.fetchGenTgDt(
            this.state.prdtct,
            this.state.productCategory,
            this.state.viewActive
          );
        });
      } else {
        this.setState({ viewActive: "" }, () => {
          this.fetchGenTgDt(
            this.state.prdtct,
            this.state.productCategory,
            this.state.viewActive
          );
        });
      }
    }
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { users,loading } = this.state;
    if (!isEmpty(users) && size(prevProps.users) !== size(users)) {
      //this.setState({ users: {}, isEdit: false });
    }
  }

  changeHandler = (e) => {
    if (e.target.value.length === 0) {
      if (this.state.prdtct === "L&F") {
        this.fetchLFtags();
      } else {
        this.resetSrch(this.state.prdtct, this.state.productCategory);
      }
    } else {
      if (this.state.prdtct === "L&F") {
        this.fetchSrchLFtags(e.target.value);
      } else {
        this.searchVal(
          this.state.prdtct,
          this.state.productCategory,
          e.target.value
        );
      }
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  onPaginationPageChange = (page) => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  /* Insert,Update Delete data */

  toggleDeleteModal = () => {
    this.setState((prevState) => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  onClickDelete = (users) => {
    this.setState({ users: users });
    this.setState({ deleteModal: true });
  };

  handleDeleteUser = () => {
    const { onDeleteUser } = this.props;
    const { users } = this.state;
    if (users.id !== undefined) {
      onDeleteUser(users);
      this.setState({ deleteModal: false });
    }
  };

  handleUserClick = (arg) => {
    const user = arg;
    //Redirect to Another Page
    window.open(
      `http://localhost:3000/#/crm-redirect-login/${user.email}`,
      "_blank" // <- This is what makes it open in a new window.
    );
  };

  updateTags(values) {
    let config = {
      method: "post",
      url: `/api/updateTagDetailsByNum`,
      headers: {
        Authorization: JSON.parse(localStorage.getItem("authUser")).token,
      },
      data: {
        tag_number: values.tag_number,
        mac_address: values.mac_address != null ? values.mac_address : null,
        tag_status: values.tag_status,
      },
    };
    axiosInstance
      .request(config)
      .then((response) => {
        // console.log("response.data.data--->", response);
        //this.setState({ genTag: response.data.data.data });
        toast.success("Tag Details Updated successfully");
        this.fetchGenTgDt(
          this.state.prdtct,
          this.state.productCategory,
          this.state.viewActive
        );
      })
      .catch((error) => {
        //console.log(error);
      });
  }

  editSingleTag = (arg) => {
    const tagData = arg;
    //console.log("tagData--->", tagData);

    //Check if tag is activated by a User
    let config = {
      method: "get",
      // maxBodyLength: Infinity,
      url: `/api/fetchuserbyTgNumber/${tagData.tag_number}`,
      headers: {
        Authorization: JSON.parse(localStorage.getItem("authUser")).token,
      },
    };

    axiosInstance
      .request(config)
      .then((response) => {
        //console.log(JSON.stringify(response.data));
        //dbLink
        if (response.data != null) {
          //generate Link to dod to db
          //console.log("response.data.data--->", response.data.data[0].email);
          /*console.log(
            "response.data.data--->",
            response.data.data[0].first_name
          );*/
          //tagUsername
          this.setState({
            dbLink: `https://dashboard.tag8.in/#/crm-redirect-login/${response.data.data[0].email}`,
            tagUsername: response.data.data[0].first_name,
          });
        }
      })
      .catch((error) => {
        //console.log(error);
      });

    this.setState({ selectedTag: tagData });
    this.toggle();
  };

  dowloadfromLink(url) {
    axios({
      url: `${BASE_URL}${url}`, //your url
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "file.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  }

  handleFile = (e) => {
    this.setState({ showLoader: true });

    if (!e.target.files) {
      return;
    }
    // setTimeout(()=>{
    //     this.setState({ showLoader: false });
    // },3000);

    let data = new FormData();
    data.append("file", e.target.files[0]);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/api/csvUploadtags`,
      data: data,
    };

    axiosInstance
      .request(config)
      .then((response) => {
        if (response.data.isError == "false") {
          toast.success(response.data.message);
          this.setState({ showLoader: false });
          if (response.data.duplicateObj.length > 0) {
            this.setState({
              dupTag:
                response.data.duplicateObj.length != 0
                  ? response.data.duplicateObj
                  : [],
              duplicateModal: true,
            });
          }
        } else if (response.data.isError == "true") {
          toast.error(response.data.message);
          this.setState({ showLoader: false });
          // this.setState({ dupTag: response.data.message.length  != 0 ? response.data.message : [] , duplicateModal:true });
        }
      })
      .catch((error) => {
        //console.log(error);
        this.setState({ showLoader: false });
      });
  };

  fetchUserDetailsByTagnumber = async (user) => {
    // try {
    // console.log("user: ", user)
    const { data } = await axiosInstance.post(
      `/api/fetchUserDetailsByTagnumber/${user.tag_number}/${user.product_category}`
    );
    // console.log("data: ", data)
    if (data.isError) {
      this.toggleUserInfoModal(); // close modal
      toast.error(data?.msg, {
        position: "top-center",
      });
    } else {
      this.setState({ userDetails: data.user });
    }
    // } catch (error) {
    //   console.log('fetchUserDetailsByTagnumber::Error fetching user details:', error);
    // }
  };

  render() {
    //meta title
    document.title = "Tags List";

    // const { users } = this.state
    const { SearchBar } = Search;

    const { users,loading } = this.state;

    const { isEdit, deleteModal } = this.state;

    const { onAddNewUser, onUpdateUser } = this.props;
    const user = this.state.user;

    const pageOptions = {
      sizePerPage: 10,
      totalSize: users.length, // replace later with size(users),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: "uid", // if dataField is not match to any column you defined, it will be ignored.
        order: "desc", // desc or asc
      },
    ];

    const selectRow = {
      mode: "checkbox",
    };

    return (
      <React.Fragment>
        
<div>
 {loading ? (
            <div style={loaderContainerStyle}>
              <div className="loader">
                <img
                  src={
                    "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                  }
                  alt="Loading..."
                  loading="lazy"
                  style={loaderStyle}
                />
              </div>
            </div>
          ) : (
<div className="page-content">
          {/* DB CArds */}
          <Row>
            <Col xl="12">
              <Row>
                <MiniWidget reports={this.state.reports} />
              </Row>
            </Col>
          </Row>

          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Tags" breadcrumbItem="Tags List" />

            <div
              style={{
                position: "absolute",
                maxWidth: "100%;",
                height: "auto",
                top: "50%",
                left: "50%",
                zIndex: "9999",
              }}
            >
              <TailSpin
                height="80"
                width="80"
                color="#556ee6"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={this.state.showLoader}
              />
            </div>

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row style={{ margin: "1rem", justifyContent: "flex-end" }}>
                      <Col
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        xl={5}
                        sm={6}
                      >
                        <div style={{ display: "flex" }}>
                          <Button
                            onClick={() => {
                              this.setState({ qrmodal: true });
                            }}
                            style={{ marginLeft: "0.2rem" }}
                            color="primary"
                            className="font-16 btn-block btn btn-primary"
                          >
                            Generate QR
                          </Button>

                          <Button
                            style={{ marginLeft: "0.2rem" }}
                            color="primary"
                            className="font-16 btn-block btn btn-primary"
                          >
                            <a
                              style={{ color: "#fff" }}
                              href="https://storage.googleapis.com/pettag/crm/TagUploadTemplate.csv"
                              rel="noreferrer"
                              target="_blank"
                            >
                              Download Template
                            </a>
                          </Button>
                          {/* <Button  onClick={ () => {  }  }  style={{marginLeft: '0.2rem'}}  color="primary" className="font-16 btn-block btn btn-primary"> 
                                                                Upload CSV
                                                                <input style={{display:"none"}} type="file" size="60" />
                                                          </Button> */}

                          <input
                            type="file"
                            id="myFileInput"
                            onChange={(e) => {
                              this.handleFile(e);
                            }}
                            style={{ display: "none" }}
                            accept="csv"
                          />
                          <input
                            type="button"
                            onClick={() => {
                              document.getElementById("myFileInput").click();
                            }}
                            style={{ marginLeft: "0.2rem" }}
                            color="primary"
                            className="font-16 btn-block btn btn-primary"
                            value="Choose file"
                          />
                        </div>
                      </Col>
                    </Row>

                    <ul className="nav nav-tabs nav-tabs-custom">
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTbl === 9 ? true : false,
                          })}
                          onClick={() => {
                            this.setState({
                              activeTbl: 9,
                              prdtct: "Tag",
                              productCategory: "Generic",
                            });
                            this.fetchGenTgDt(
                              "Tag",
                              "Generic",
                              this.state.viewActive
                            );
                          }}
                        >
                          Generic
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTbl === 1 ? true : false,
                          })}
                          onClick={() => {
                            //this.props.toggleTab("1")
                            //console.log("Tab 1 Clicked");
                            this.setState({
                              activeTbl: 1,
                              prdtct: "Tag",
                              productCategory: "Dolphin Smart Tracker",
                            });
                            this.fetchGenTgDt(
                              "Tag",
                              "Dolphin Smart Tracker",
                              this.state.viewActive
                            );
                          }}
                        >
                          Dolphin Smart Tracker
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTbl === 2 ? true : false,
                          })}
                          onClick={() => {
                            // this.props.toggleTab("2")
                            //console.log("Tab 2 Clicked");
                            this.setState({
                              activeTbl: 2,
                              prdtct: "Tag",
                              productCategory: "QR PET",
                            });
                            this.fetchGenTgDt(
                              "Tag",
                              "QR PET",
                              this.state.viewActive
                            );
                          }}
                        >
                          QR PET
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTbl === 3 ? true : false,
                          })}
                          onClick={() => {
                            // this.props.toggleTab("3")
                            // this.setState({ activeTbl: 3 });
                            // console.log("Tab 3 Clicked");

                            this.setState({
                              activeTbl: 3,
                              prdtct: "Tag",
                              productCategory: "QR Gadget",
                            });
                            this.fetchGenTgDt(
                              "Tag",
                              "QR Gadget",
                              this.state.viewActive
                            );
                          }}
                        >
                          QR Gadget
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTbl === 4 ? true : false,
                          })}
                          onClick={() => {
                            this.setState({
                              activeTbl: 4,
                              prdtct: "Tag",
                              productCategory: "Dolphin Bag",
                            });
                            this.fetchGenTgDt(
                              "Tag",
                              "Dolphin Bag",
                              this.state.viewActive
                            );
                          }}
                        >
                          Dolphin Bag
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTbl === 5 ? true : false,
                          })}
                          onClick={() => {
                            this.setState({
                              activeTbl: 5,
                              prdtct: "Tag",
                              productCategory: "Smart Wallet",
                            });
                            this.fetchGenTgDt(
                              "Tag",
                              "Smart Wallet",
                              this.state.viewActive
                            );
                          }}
                        >
                          Smart Wallet
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTbl === 6 ? true : false,
                          })}
                          onClick={() => {
                            this.setState({
                              activeTbl: 6,
                              prdtct: "Tag",
                              productCategory: "QR Wallet",
                            });
                            this.fetchGenTgDt(
                              "Tag",
                              "QR Wallet",
                              this.state.viewActive
                            );
                          }}
                        >
                          QR Wallet
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTbl === 7 ? true : false,
                          })}
                          onClick={() => {
                            this.setState({
                              activeTbl: 7,
                              prdtct: "Tag",
                              productCategory: "QR Key",
                            });
                            this.fetchGenTgDt(
                              "Tag",
                              "QR Key",
                              this.state.viewActive
                            );
                          }}
                        >
                          QR Key
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTbl === 8 ? true : false,
                          })}
                          onClick={() => {
                            this.setState({
                              activeTbl: 8,
                              prdtct: "Tag",
                              productCategory: "QR Bag",
                            });
                            this.fetchGenTgDt(
                              "Tag",
                              "QR Bag",
                              this.state.viewActive
                            );
                          }}
                        >
                          QR Bag
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTbl === 10 ? true : false,
                          })}
                          onClick={() => {
                            this.setState({
                              activeTbl: 10,
                              prdtct: "L&F",
                              productCategory: "L&F",
                            });
                            // this.fetchGenTgDt('Tag'  , 'QR bag');
                            this.fetchLFtags();
                          }}
                        >
                          Lost and Found Tags
                        </NavLink>
                      </NavItem>

                      {/* QR Medical Tags */}
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTbl === 11 ? true : false,
                          })}
                          onClick={() => {
                            this.setState({
                              activeTbl: 11,
                              prdtct: "Tag",
                              productCategory: "QR Medical",
                            });
                            this.fetchGenTgDt(
                              "Tag",
                              "QR Medical",
                              this.state.viewActive
                            );
                          }}
                        >
                          QR Medical
                        </NavLink>
                      </NavItem>

                      {/* E-Passport */}
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTbl === 12 ? true : false,
                          })}
                          onClick={() => {
                            this.setState({
                              activeTbl: 12,
                              prdtct: "Tag",
                              productCategory: "E-Passport",
                            });
                            this.fetchGenTgDt(
                              "Tag",
                              "E-Passport",
                              this.state.viewActive
                            );
                          }}
                        >
                          E-Passport
                        </NavLink>
                      </NavItem>

                      {/* E-Bag */}
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTbl === 13 ? true : false,
                          })}
                          onClick={() => {
                            this.setState({
                              activeTbl: 13,
                              prdtct: "Tag",
                              productCategory: "E-Bag",
                            });
                            this.fetchGenTgDt(
                              "Tag",
                              "E-Bag",
                              this.state.viewActive
                            );
                          }}
                        >
                          E-Bag
                        </NavLink>
                      </NavItem>
                      {/* QR Passport */}
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTbl === 14 ? true : false,
                          })}
                          onClick={() => {
                            this.setState({
                              activeTbl: 14,
                              prdtct: "Tag",
                              productCategory: "QR Passport",
                            });
                            this.fetchGenTgDt(
                              "Tag",
                              "QR Passport",
                              this.state.viewActive
                            );
                          }}
                        >
                          QR Passport
                        </NavLink>
                      </NavItem>
                      {/* QR Human */}
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTbl === 15 ? true : false,
                          })}
                          onClick={() => {
                            this.setState({
                              activeTbl: 15,
                              prdtct: "Tag",
                              productCategory: "QR Human",
                            });
                            this.fetchGenTgDt(
                              "Tag",
                              "QR Human",
                              this.state.viewActive
                            );
                          }}
                        >
                          QR Human
                        </NavLink>
                      </NavItem>
                      {/* QR Vehicle */}
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTbl === 16 ? true : false,
                          })}
                          onClick={() => {
                            this.setState({
                              activeTbl: 16,
                              prdtct: "Tag",
                              productCategory: "QR Vehicle",
                            });
                            this.fetchGenTgDt(
                              "Tag",
                              "QR Vehicle",
                              this.state.viewActive
                            );
                          }}
                        >
                          QR Vehicle
                        </NavLink>
                      </NavItem>
                      
                    </ul>

                    <Modal
                      isOpen={this.state.modal_standard}
                      toggle={this.tog_standard}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                          Delete Tag
                        </h5>
                        <button
                          type="button"
                          onClick={() =>
                            this.setState({ modal_standard: false })
                          }
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <h5>Are you sure you want ot delete Tag ?</h5>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={this.tog_standard}
                          className="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            this.deleteTag();
                          }}
                        >
                          Confirm
                        </button>
                      </div>
                    </Modal>

                    <Modal
                      isOpen={this.state.modal}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggle} tag="h4">
                        Edit Tag
                      </ModalHeader>
                      <ModalBody>
                        <Formik
                          enableReinitialize={true}
                          initialValues={{
                            tag_number:
                              (this.state.selectedTag &&
                                this.state.selectedTag.tag_number) ||
                              "",
                            product_category:
                              (this.state.selectedTag &&
                                this.state.selectedTag.product_category) ||
                              "",
                            tag_status:
                              (this.state.selectedTag &&
                                this.state.selectedTag.tag_status) ||
                              "",
                            mac_address:
                              (this.state.selectedTag &&
                                this.state.selectedTag.mac_address) ||
                              "",
                            create_date:
                              (this.state.selectedTag &&
                                new Date(
                                  this.state.selectedTag.create_date
                                ).toLocaleDateString()) ||
                              [],
                            //   projects:
                            //     (user && user.projects) || "",
                          }}
                          validationSchema={Yup.object().shape({
                            tag_number: Yup.string().required(
                              "Please Enter Tag Number"
                            ),
                            product_category: Yup.string().required(
                              "Please Enter Product Category"
                            ),
                            mac_address: Yup.string().required(
                              "Please Enter Mac Address"
                            ),
                            tag_status: Yup.string().required(
                              "Please Enter Tag Status"
                            ),
                            create_date: Yup.string().required(
                              "Please Update Selected Date"
                            ),
                          })}
                          onSubmit={(values) => {
                            this.updateTags(values);
                            this.setState({
                              selectedTag: null,
                            });
                            this.toggle();
                          }}
                        >
                          {({ errors, status, touched }) => (
                            <Form>
                              <Row>
                                <Col className="col-12">
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Tag Number
                                    </Label>
                                    <Field
                                      readOnly
                                      name="tag_number"
                                      type="text"
                                      className={
                                        "form-control" +
                                        (errors.tag_number && touched.tag_number
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="tag_number"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Product Category
                                    </Label>
                                    <Field
                                      readOnly
                                      name="product_category"
                                      type="text"
                                      className={
                                        "form-control" +
                                        (errors.product_category &&
                                        touched.product_category
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="product_category"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>

                                  {(this.state.activeTbl === 1 ||
                                    this.state.activeTbl === 4 ||
                                    this.state.activeTbl === 5) && (
                                    <div className="mb-3">
                                      <Label className="form-label">
                                        Mac Address
                                      </Label>
                                      <Field
                                        placeholder="Enter Mac Address"
                                        name="mac_address"
                                        type="text"
                                        className={
                                          "form-control" +
                                          (errors.mac_address &&
                                          touched.mac_address
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="mac_address"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  )}

                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Created Date
                                    </Label>
                                    <Field
                                      readOnly
                                      name="create_date"
                                      type="text"
                                      className={
                                        "form-control" +
                                        (errors.create_date &&
                                        touched.create_date
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="create_date"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>

                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Tag Status
                                    </Label>
                                    <Field
                                      name="tag_status"
                                      as="select"
                                      className={
                                        "form-control" +
                                        (errors.tag_status && touched.tag_status
                                          ? " is-invalid"
                                          : "")
                                      }
                                    >
                                      <option>active</option>
                                      <option>inactive</option>
                                    </Field>
                                    <ErrorMessage
                                      name="tag_status"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                  {this.state.dbLink != null && (
                                    <>
                                      <div className="mb-2">
                                        <Label className="form-label">
                                          Owner Name :
                                          <a
                                            id="linkTooltip"
                                            href={this.state.dbLink}
                                            target="blank"
                                          >
                                            {this.state.tagUsername}{" "}
                                          </a>
                                          <i
                                            className="mdi mdi-info font-size-18"
                                            id="linkTooltip"
                                          ></i>
                                          <UncontrolledTooltip
                                            placement="top"
                                            target="linkTooltip"
                                          >
                                            Click on the link to view Dashboard
                                          </UncontrolledTooltip>
                                        </Label>
                                      </div>
                                    </>
                                  )}
                                </Col>
                              </Row>

                              <Row>
                                <Col>
                                  <div className="text-end">
                                    <button
                                      type="submit"
                                      className="btn btn-success save-user"
                                    >
                                      Save
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          )}
                        </Formik>
                      </ModalBody>
                    </Modal>

                    <Modal
                      size="lg"
                      isOpen={this.state.duplicateModal}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggleDup} tag="h4">
                        Duplicate Tags
                      </ModalHeader>
                      <ModalBody>
                        <BootstrapTable
                          keyField="id"
                          data={this.state.dupTag}
                          columns={this.state.columnsDupTag}
                          pagination={paginationFactory()}
                        />
                      </ModalBody>
                    </Modal>

                    {/* USER INFO MODAL */}
                    <Modal
                      isOpen={this.state.userInfoModal}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggleUserInfoModal} tag="h4">
                        User Information
                      </ModalHeader>
                      <ModalBody>
                        <Formik
                          enableReinitialize={true}
                          initialValues={{
                            firstName: this.state.userDetails?.first_name
                              ? this.state.userDetails?.first_name
                              : "",
                            lastName: this.state.userDetails?.last_name
                              ? this.state.userDetails?.last_name
                              : "",
                            phoneNumber:
                              this.state.userDetails?.phonecountrycode &&
                              this.state.userDetails?.phone
                                ? `${this.state.userDetails?.phonecountrycode} ${this.state.userDetails?.phone}`
                                : "",
                            email: this.state.userDetails?.email
                              ? this.state.userDetails?.email
                              : "",
                          }}
                        >
                          {({}) => (
                            <Form>
                              <Row>
                                <Col className="col-12">
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      First Name
                                    </Label>
                                    <Field
                                      readOnly
                                      name="firstName"
                                      type="text"
                                      className={"form-control"}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Last Name
                                    </Label>
                                    <Field
                                      readOnly
                                      name="lastName"
                                      type="text"
                                      className={"form-control"}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <Label className="form-label">Email</Label>
                                    <Field
                                      readOnly
                                      name="email"
                                      type="text"
                                      className={"form-control"}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Phone Number
                                    </Label>
                                    <Field
                                      readOnly
                                      name="phoneNumber"
                                      type="text"
                                      className={"form-control"}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          )}
                        </Formik>
                      </ModalBody>
                    </Modal>

                    <div className="mt-4">
                      {this.state.activeTbl === 1 && (
                        <>
                          <div className="table-responsive">
                            <Row style={{ margin: "1rem" }}>
                              <Col style={{ display: "flex" }} xl={3} sm={6}>
                                <Flatpickr
                                  className="form-control d-block"
                                  placeholder="Select Range"
                                  options={{
                                    mode: "range",
                                    dateFormat: "Y-m-d",
                                  }}
                                  onChange={(date) => {
                                    this.setState({
                                      dateRange: false,
                                      selectedRange: date,
                                    });
                                  }}
                                  id="dtRngPick"
                                />
                                {this.state.dateRange ? (
                                  <Button
                                    onClick={() => {
                                      this.fetchGenTgDt(
                                        this.state.prdtct,
                                        this.state.productCategory,
                                        this.state.viewActive
                                      );
                                      this.setState({ selectedRange: [] });
                                      window.location.reload();
                                    }}
                                    style={{ marginLeft: "0.2rem" }}
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    Reset
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      this.fetchBasedOnDtRange();
                                    }}
                                    style={{ marginLeft: "0.2rem" }}
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    Apply
                                  </Button>
                                )}
                              </Col>
                              <Col xl={5} sm={6}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={
                                    this.state.viewActive === "active"
                                      ? true
                                      : false
                                  }
                                  id="defaultCheck1"
                                  onChange={(e) => {
                                    this.displayActv(e.target.checked);
                                  }} //e.target.checked   }}  e.target.checked === true ?   this.setState({  viewActive:'active'}) : this.setState({  viewActive:''}) ; this.fetchGenTgDt(this.state.prdtct,this.state.productCategory)
                                />
                                &nbsp;&nbsp;
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck1"
                                >
                                  View Active
                                </label>
                              </Col>

                              <Col
                                style={{ display: "-webkit-inline-box" }}
                                xl={3}
                                sm={6}
                              >
                                <input
                                  type="search"
                                  id="form1"
                                  placeholder="Search By Tag Number"
                                  className="form-control"
                                  name="searchkey"
                                  value={this.state.searchkey}
                                  onChange={this.changeHandler}
                                />

                                <Button
                                  onClick={() => {
                                    this.exlfetchGenTgDt(
                                      this.state.prdtct,
                                      this.state.productCategory,
                                      this.state.viewActive
                                    );
                                  }}
                                  style={{ marginLeft: "0.2rem" }}
                                  color="primary"
                                  className="font-16 btn-block btn btn-primary"
                                >
                                  Export CSV
                                </Button>
                              </Col>
                            </Row>
                            <BootstrapTable
                              keyField="id"
                              data={this.state.genTag}
                              columns={this.state.columnsGenTag}
                              pagination={paginationFactory()}
                            />
                          </div>
                        </>
                      )}
                      {
                        this.state.activeTbl === 2 && (
                          <>
                            <div className="table-responsive">
                              <Row style={{ margin: "1rem" }}>
                                <Col style={{ display: "flex" }} xl={3} sm={6}>
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="Select Range"
                                    options={{
                                      mode: "range",
                                      dateFormat: "Y-m-d",
                                    }}
                                    onChange={(date) => {
                                      this.setState({
                                        dateRange: false,
                                        selectedRange: date,
                                      });
                                    }}
                                    id="dtRngPick"
                                  />
                                  {this.state.dateRange ? (
                                    <Button
                                      onClick={() => {
                                        this.fetchGenTgDt(
                                          this.state.prdtct,
                                          this.state.productCategory,
                                          this.state.viewActive
                                        );
                                        this.setState({ selectedRange: [] });
                                        window.location.reload();
                                      }}
                                      style={{ marginLeft: "0.2rem" }}
                                      color="primary"
                                      className="font-16 btn-block btn btn-primary"
                                    >
                                      Reset
                                    </Button>
                                  ) : (
                                    <Button
                                      onClick={() => {
                                        this.fetchBasedOnDtRange();
                                      }}
                                      style={{ marginLeft: "0.2rem" }}
                                      color="primary"
                                      className="font-16 btn-block btn btn-primary"
                                    >
                                      Apply
                                    </Button>
                                  )}
                                </Col>
                                <Col xl={5} sm={6}>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={
                                      this.state.viewActive === "active"
                                        ? true
                                        : false
                                    }
                                    id="defaultCheck1"
                                    onChange={(e) => {
                                      this.displayActv(e.target.checked);
                                    }} //e.target.checked   }}  e.target.checked === true ?   this.setState({  viewActive:'active'}) : this.setState({  viewActive:''}) ; this.fetchGenTgDt(this.state.prdtct,this.state.productCategory)
                                  />
                                  &nbsp;&nbsp;
                                  <label
                                    className="form-check-label"
                                    htmlFor="defaultCheck1"
                                  >
                                    View Active
                                  </label>
                                </Col>

                                <Col
                                  style={{ display: "-webkit-inline-box" }}
                                  xl={3}
                                  sm={6}
                                >
                                  <input
                                    type="search"
                                    id="form1"
                                    placeholder="Search By Tag Number"
                                    className="form-control"
                                    name="searchkey"
                                    value={this.state.searchkey}
                                    onChange={this.changeHandler}
                                  />

                                  <Button
                                    onClick={() => {
                                      this.exlfetchGenTgDt(
                                        this.state.prdtct,
                                        this.state.productCategory,
                                        this.state.viewActive
                                      );
                                    }}
                                    style={{ marginLeft: "0.2rem" }}
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    Export CSV
                                  </Button>
                                </Col>
                              </Row>
                              <BootstrapTable
                                keyField="id"
                                data={this.state.genTag}
                                columns={this.state.columnsGenTag}
                                pagination={paginationFactory()}
                              />
                            </div>
                          </>
                        )

                        // <BootstrapTable keyField='id' data={this.state.productData1} columns={this.state.columns} pagination={paginationFactory()} />
                      }
                      {this.state.activeTbl === 3 && (
                        <>
                          <div className="table-responsive">
                            <Row style={{ margin: "1rem" }}>
                              <Col style={{ display: "flex" }} xl={3} sm={6}>
                                <Flatpickr
                                  className="form-control d-block"
                                  placeholder="Select Range"
                                  options={{
                                    mode: "range",
                                    dateFormat: "Y-m-d",
                                  }}
                                  onChange={(date) => {
                                    this.setState({
                                      dateRange: false,
                                      selectedRange: date,
                                    });
                                  }}
                                  id="dtRngPick"
                                />
                                {this.state.dateRange ? (
                                  <Button
                                    onClick={() => {
                                      this.fetchGenTgDt(
                                        this.state.prdtct,
                                        this.state.productCategory,
                                        this.state.viewActive
                                      );
                                      this.setState({ selectedRange: [] });
                                      window.location.reload();
                                    }}
                                    style={{ marginLeft: "0.2rem" }}
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    Reset
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      this.fetchBasedOnDtRange();
                                    }}
                                    style={{ marginLeft: "0.2rem" }}
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    Apply
                                  </Button>
                                )}
                              </Col>
                              <Col xl={5} sm={6}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={
                                    this.state.viewActive === "active"
                                      ? true
                                      : false
                                  }
                                  id="defaultCheck1"
                                  onChange={(e) => {
                                    this.displayActv(e.target.checked);
                                  }} //e.target.checked   }}  e.target.checked === true ?   this.setState({  viewActive:'active'}) : this.setState({  viewActive:''}) ; this.fetchGenTgDt(this.state.prdtct,this.state.productCategory)
                                />
                                &nbsp;&nbsp;
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck1"
                                >
                                  View Active
                                </label>
                              </Col>

                              <Col
                                style={{ display: "-webkit-inline-box" }}
                                xl={3}
                                sm={6}
                              >
                                <input
                                  type="search"
                                  id="form1"
                                  placeholder="Search By Tag Number"
                                  className="form-control"
                                  name="searchkey"
                                  value={this.state.searchkey}
                                  onChange={this.changeHandler}
                                />

                                <Button
                                  onClick={() => {
                                    this.exlfetchGenTgDt(
                                      this.state.prdtct,
                                      this.state.productCategory,
                                      this.state.viewActive
                                    );
                                  }}
                                  style={{ marginLeft: "0.2rem" }}
                                  color="primary"
                                  className="font-16 btn-block btn btn-primary"
                                >
                                  Export CSV
                                </Button>
                              </Col>
                            </Row>
                            <BootstrapTable
                              keyField="id"
                              data={this.state.genTag}
                              columns={this.state.columnsGenTag}
                              pagination={paginationFactory()}
                            />
                          </div>
                        </>
                      )}
                      {this.state.activeTbl === 4 && (
                        <>
                          <div className="table-responsive">
                            <Row style={{ margin: "1rem" }}>
                              <Col style={{ display: "flex" }} xl={3} sm={6}>
                                <Flatpickr
                                  className="form-control d-block"
                                  placeholder="Select Range"
                                  options={{
                                    mode: "range",
                                    dateFormat: "Y-m-d",
                                  }}
                                  onChange={(date) => {
                                    this.setState({
                                      dateRange: false,
                                      selectedRange: date,
                                    });
                                  }}
                                  id="dtRngPick"
                                />
                                {this.state.dateRange ? (
                                  <Button
                                    onClick={() => {
                                      this.fetchGenTgDt(
                                        this.state.prdtct,
                                        this.state.productCategory,
                                        this.state.viewActive
                                      );
                                      this.setState({ selectedRange: [] });
                                      window.location.reload();
                                    }}
                                    style={{ marginLeft: "0.2rem" }}
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    Reset
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      this.fetchBasedOnDtRange();
                                    }}
                                    style={{ marginLeft: "0.2rem" }}
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    Apply
                                  </Button>
                                )}
                              </Col>
                              <Col xl={5} sm={6}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={
                                    this.state.viewActive === "active"
                                      ? true
                                      : false
                                  }
                                  id="defaultCheck1"
                                  onChange={(e) => {
                                    this.displayActv(e.target.checked);
                                  }} //e.target.checked   }}  e.target.checked === true ?   this.setState({  viewActive:'active'}) : this.setState({  viewActive:''}) ; this.fetchGenTgDt(this.state.prdtct,this.state.productCategory)
                                />
                                &nbsp;&nbsp;
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck1"
                                >
                                  View Active
                                </label>
                              </Col>

                              <Col
                                style={{ display: "-webkit-inline-box" }}
                                xl={3}
                                sm={6}
                              >
                                <input
                                  type="search"
                                  id="form1"
                                  placeholder="Search By Tag Number"
                                  className="form-control"
                                  name="searchkey"
                                  value={this.state.searchkey}
                                  onChange={this.changeHandler}
                                />

                                <Button
                                  onClick={() => {
                                    this.exlfetchGenTgDt(
                                      this.state.prdtct,
                                      this.state.productCategory,
                                      this.state.viewActive
                                    );
                                  }}
                                  style={{ marginLeft: "0.2rem" }}
                                  color="primary"
                                  className="font-16 btn-block btn btn-primary"
                                >
                                  Export CSV
                                </Button>
                              </Col>
                            </Row>
                            <BootstrapTable
                              keyField="id"
                              data={this.state.genTag}
                              columns={this.state.columnsGenTag}
                              pagination={paginationFactory()}
                            />
                          </div>
                        </>
                      )}
                      {this.state.activeTbl === 5 && (
                        <>
                          <div className="table-responsive">
                            <Row style={{ margin: "1rem" }}>
                              <Col style={{ display: "flex" }} xl={3} sm={6}>
                                <Flatpickr
                                  className="form-control d-block"
                                  placeholder="Select Range"
                                  options={{
                                    mode: "range",
                                    dateFormat: "Y-m-d",
                                  }}
                                  onChange={(date) => {
                                    this.setState({
                                      dateRange: false,
                                      selectedRange: date,
                                    });
                                  }}
                                  id="dtRngPick"
                                />
                                {this.state.dateRange ? (
                                  <Button
                                    onClick={() => {
                                      this.fetchGenTgDt(
                                        this.state.prdtct,
                                        this.state.productCategory,
                                        this.state.viewActive
                                      );
                                      this.setState({ selectedRange: [] });
                                      window.location.reload();
                                    }}
                                    style={{ marginLeft: "0.2rem" }}
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    Reset
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      this.fetchBasedOnDtRange();
                                    }}
                                    style={{ marginLeft: "0.2rem" }}
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    Apply
                                  </Button>
                                )}
                              </Col>
                              <Col xl={5} sm={6}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={
                                    this.state.viewActive === "active"
                                      ? true
                                      : false
                                  }
                                  id="defaultCheck1"
                                  onChange={(e) => {
                                    this.displayActv(e.target.checked);
                                  }} //e.target.checked   }}  e.target.checked === true ?   this.setState({  viewActive:'active'}) : this.setState({  viewActive:''}) ; this.fetchGenTgDt(this.state.prdtct,this.state.productCategory)
                                />
                                &nbsp;&nbsp;
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck1"
                                >
                                  View Active
                                </label>
                              </Col>

                              <Col
                                style={{ display: "-webkit-inline-box" }}
                                xl={3}
                                sm={6}
                              >
                                <input
                                  type="search"
                                  id="form1"
                                  placeholder="Search By Tag Number"
                                  className="form-control"
                                  name="searchkey"
                                  value={this.state.searchkey}
                                  onChange={this.changeHandler}
                                />

                                <Button
                                  onClick={() => {
                                    this.exlfetchGenTgDt(
                                      this.state.prdtct,
                                      this.state.productCategory,
                                      this.state.viewActive
                                    );
                                  }}
                                  style={{ marginLeft: "0.2rem" }}
                                  color="primary"
                                  className="font-16 btn-block btn btn-primary"
                                >
                                  Export CSV
                                </Button>
                              </Col>
                            </Row>
                            <BootstrapTable
                              keyField="id"
                              data={this.state.genTag}
                              columns={this.state.columnsGenTag}
                              pagination={paginationFactory()}
                            />
                          </div>
                        </>
                      )}

                      {this.state.activeTbl === 6 && (
                        <>
                          <div className="table-responsive">
                            <Row style={{ margin: "1rem" }}>
                              <Col style={{ display: "flex" }} xl={3} sm={6}>
                                <Flatpickr
                                  className="form-control d-block"
                                  placeholder="Select Range"
                                  options={{
                                    mode: "range",
                                    dateFormat: "Y-m-d",
                                  }}
                                  onChange={(date) => {
                                    this.setState({
                                      dateRange: false,
                                      selectedRange: date,
                                    });
                                  }}
                                  id="dtRngPick"
                                />
                                {this.state.dateRange ? (
                                  <Button
                                    onClick={() => {
                                      this.fetchGenTgDt(
                                        this.state.prdtct,
                                        this.state.productCategory,
                                        this.state.viewActive
                                      );
                                      this.setState({ selectedRange: [] });
                                      window.location.reload();
                                    }}
                                    style={{ marginLeft: "0.2rem" }}
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    Reset
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      this.fetchBasedOnDtRange();
                                    }}
                                    style={{ marginLeft: "0.2rem" }}
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    Apply
                                  </Button>
                                )}
                              </Col>
                              <Col xl={5} sm={6}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={
                                    this.state.viewActive === "active"
                                      ? true
                                      : false
                                  }
                                  id="defaultCheck1"
                                  onChange={(e) => {
                                    this.displayActv(e.target.checked);
                                  }} //e.target.checked   }}  e.target.checked === true ?   this.setState({  viewActive:'active'}) : this.setState({  viewActive:''}) ; this.fetchGenTgDt(this.state.prdtct,this.state.productCategory)
                                />
                                &nbsp;&nbsp;
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck1"
                                >
                                  View Active
                                </label>
                              </Col>

                              <Col
                                style={{ display: "-webkit-inline-box" }}
                                xl={3}
                                sm={6}
                              >
                                <input
                                  type="search"
                                  id="form1"
                                  placeholder="Search By Tag Number"
                                  className="form-control"
                                  name="searchkey"
                                  value={this.state.searchkey}
                                  onChange={this.changeHandler}
                                />

                                <Button
                                  onClick={() => {
                                    this.exlfetchGenTgDt(
                                      this.state.prdtct,
                                      this.state.productCategory,
                                      this.state.viewActive
                                    );
                                  }}
                                  style={{ marginLeft: "0.2rem" }}
                                  color="primary"
                                  className="font-16 btn-block btn btn-primary"
                                >
                                  Export CSV
                                </Button>
                              </Col>
                            </Row>
                            <BootstrapTable
                              keyField="id"
                              data={this.state.genTag}
                              columns={this.state.columnsGenTag}
                              pagination={paginationFactory()}
                            />
                          </div>
                        </>
                      )}

                      {this.state.activeTbl === 7 && (
                        <>
                          <div className="table-responsive">
                            <Row style={{ margin: "1rem" }}>
                              <Col style={{ display: "flex" }} xl={3} sm={6}>
                                <Flatpickr
                                  className="form-control d-block"
                                  placeholder="Select Range"
                                  options={{
                                    mode: "range",
                                    dateFormat: "Y-m-d",
                                  }}
                                  onChange={(date) => {
                                    this.setState({
                                      dateRange: false,
                                      selectedRange: date,
                                    });
                                  }}
                                  id="dtRngPick"
                                />
                                {this.state.dateRange ? (
                                  <Button
                                    onClick={() => {
                                      this.fetchGenTgDt(
                                        this.state.prdtct,
                                        this.state.productCategory,
                                        this.state.viewActive
                                      );
                                      this.setState({ selectedRange: [] });
                                      window.location.reload();
                                    }}
                                    style={{ marginLeft: "0.2rem" }}
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    Reset
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      this.fetchBasedOnDtRange();
                                    }}
                                    style={{ marginLeft: "0.2rem" }}
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    Apply
                                  </Button>
                                )}
                              </Col>
                              <Col xl={5} sm={6}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={
                                    this.state.viewActive === "active"
                                      ? true
                                      : false
                                  }
                                  id="defaultCheck1"
                                  onChange={(e) => {
                                    this.displayActv(e.target.checked);
                                  }} //e.target.checked   }}  e.target.checked === true ?   this.setState({  viewActive:'active'}) : this.setState({  viewActive:''}) ; this.fetchGenTgDt(this.state.prdtct,this.state.productCategory)
                                />
                                &nbsp;&nbsp;
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck1"
                                >
                                  View Active
                                </label>
                              </Col>

                              <Col
                                style={{ display: "-webkit-inline-box" }}
                                xl={3}
                                sm={6}
                              >
                                <input
                                  type="search"
                                  id="form1"
                                  placeholder="Search By Tag Number"
                                  className="form-control"
                                  name="searchkey"
                                  value={this.state.searchkey}
                                  onChange={this.changeHandler}
                                />

                                <Button
                                  onClick={() => {
                                    this.exlfetchGenTgDt(
                                      this.state.prdtct,
                                      this.state.productCategory,
                                      this.state.viewActive
                                    );
                                  }}
                                  style={{ marginLeft: "0.2rem" }}
                                  color="primary"
                                  className="font-16 btn-block btn btn-primary"
                                >
                                  Export CSV
                                </Button>
                              </Col>
                            </Row>
                            <BootstrapTable
                              keyField="id"
                              data={this.state.genTag}
                              columns={this.state.columnsGenTag}
                              pagination={paginationFactory()}
                            />
                          </div>
                        </>
                      )}

                      {this.state.activeTbl === 8 && (
                        <>
                          <div className="table-responsive">
                            <Row style={{ margin: "1rem" }}>
                              <Col style={{ display: "flex" }} xl={3} sm={6}>
                                <Flatpickr
                                  className="form-control d-block"
                                  placeholder="Select Range"
                                  options={{
                                    mode: "range",
                                    dateFormat: "Y-m-d",
                                  }}
                                  onChange={(date) => {
                                    this.setState({
                                      dateRange: false,
                                      selectedRange: date,
                                    });
                                  }}
                                  id="dtRngPick"
                                />
                                {this.state.dateRange ? (
                                  <Button
                                    onClick={() => {
                                      this.fetchGenTgDt(
                                        this.state.prdtct,
                                        this.state.productCategory,
                                        this.state.viewActive
                                      );
                                      this.setState({ selectedRange: [] });
                                      window.location.reload();
                                    }}
                                    style={{ marginLeft: "0.2rem" }}
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    Reset
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      this.fetchBasedOnDtRange();
                                    }}
                                    style={{ marginLeft: "0.2rem" }}
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    Apply
                                  </Button>
                                )}
                              </Col>
                              <Col xl={5} sm={6}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={
                                    this.state.viewActive === "active"
                                      ? true
                                      : false
                                  }
                                  id="defaultCheck1"
                                  onChange={(e) => {
                                    this.displayActv(e.target.checked);
                                  }} //e.target.checked   }}  e.target.checked === true ?   this.setState({  viewActive:'active'}) : this.setState({  viewActive:''}) ; this.fetchGenTgDt(this.state.prdtct,this.state.productCategory)
                                />
                                &nbsp;&nbsp;
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck1"
                                >
                                  View Active
                                </label>
                              </Col>

                              <Col
                                style={{ display: "-webkit-inline-box" }}
                                xl={3}
                                sm={6}
                              >
                                <input
                                  type="search"
                                  id="form1"
                                  placeholder="Search By Tag Number"
                                  className="form-control"
                                  name="searchkey"
                                  value={this.state.searchkey}
                                  onChange={this.changeHandler}
                                />

                                <Button
                                  onClick={() => {
                                    this.exlfetchGenTgDt(
                                      this.state.prdtct,
                                      this.state.productCategory,
                                      this.state.viewActive
                                    );
                                  }}
                                  style={{ marginLeft: "0.2rem" }}
                                  color="primary"
                                  className="font-16 btn-block btn btn-primary"
                                >
                                  Export CSV
                                </Button>
                              </Col>
                            </Row>

                            <BootstrapTable
                              keyField="id"
                              data={this.state.genTag}
                              columns={this.state.columnsGenTag}
                              pagination={paginationFactory()}
                            />
                          </div>
                        </>
                      )}

                      {this.state.activeTbl === 9 && (
                        <>
                          <div className="table-responsive">
                            <Row style={{ margin: "1rem" }}>
                              <Col style={{ display: "flex" }} xl={3} sm={6}>
                                <Flatpickr
                                  className="form-control d-block"
                                  placeholder="Select Range"
                                  options={{
                                    mode: "range",
                                    dateFormat: "Y-m-d",
                                  }}
                                  onChange={(date) => {
                                    this.setState({
                                      dateRange: false,
                                      selectedRange: date,
                                    });
                                  }}
                                  id="dtRngPick"
                                />
                                {this.state.dateRange ? (
                                  <Button
                                    onClick={() => {
                                      this.fetchGenTgDt(
                                        this.state.prdtct,
                                        this.state.productCategory,
                                        this.state.viewActive
                                      );
                                      this.setState({ selectedRange: [] });
                                      window.location.reload();
                                    }}
                                    style={{ marginLeft: "0.2rem" }}
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    Reset
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      this.fetchBasedOnDtRange();
                                    }}
                                    style={{ marginLeft: "0.2rem" }}
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    Apply
                                  </Button>
                                )}
                              </Col>
                              <Col xl={5} sm={6}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={
                                    this.state.viewActive === "active"
                                      ? true
                                      : false
                                  }
                                  id="defaultCheck1"
                                  onChange={(e) => {
                                    this.displayActv(e.target.checked);
                                  }} //e.target.checked   }}  e.target.checked === true ?   this.setState({  viewActive:'active'}) : this.setState({  viewActive:''}) ; this.fetchGenTgDt(this.state.prdtct,this.state.productCategory)
                                />
                                &nbsp;&nbsp;
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck1"
                                >
                                  View Active
                                </label>
                              </Col>

                              <Col
                                style={{ display: "-webkit-inline-box" }}
                                xl={3}
                                sm={6}
                              >
                                <input
                                  type="search"
                                  id="form1"
                                  placeholder="Search By Tag Number"
                                  className="form-control"
                                  name="searchkey"
                                  value={this.state.searchkey}
                                  onChange={this.changeHandler}
                                />

                                <Button
                                  onClick={() => {
                                    this.exlfetchGenTgDt(
                                      this.state.prdtct,
                                      this.state.productCategory,
                                      this.state.viewActive
                                    );
                                  }}
                                  style={{ marginLeft: "0.2rem" }}
                                  color="primary"
                                  className="font-16 btn-block btn btn-primary"
                                >
                                  Export CSV
                                </Button>
                              </Col>
                            </Row>

                            <BootstrapTable
                              keyField="id"
                              data={this.state.genTag}
                              columns={this.state.columnsGenTag}
                              pagination={paginationFactory()}
                            />
                          </div>
                        </>
                      )}

                      {this.state.activeTbl === 10 && (
                        <>
                          <div className="table-responsive">
                            <Row style={{ margin: "1rem" }}>
                              <Col style={{ display: "flex" }} xl={3} sm={6}>
                                <Flatpickr
                                  className="form-control d-block"
                                  placeholder="Select Range"
                                  options={{
                                    mode: "range",
                                    dateFormat: "Y-m-d",
                                  }}
                                  onChange={(date) => {
                                    this.setState({
                                      dateRange: false,
                                      selectedRange: date,
                                    });
                                  }}
                                  id="dtRngPick"
                                />
                                {this.state.dateRange ? (
                                  <Button
                                    onClick={() => {
                                      this.fetchGenTgDt(
                                        this.state.prdtct,
                                        this.state.productCategory,
                                        this.state.viewActive
                                      );
                                      this.setState({ selectedRange: [] });
                                      window.location.reload();
                                    }}
                                    style={{ marginLeft: "0.2rem" }}
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    Reset
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      this.fetchBasedOnDtRange();
                                    }}
                                    style={{ marginLeft: "0.2rem" }}
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    Apply
                                  </Button>
                                )}
                              </Col>
                              <Col xl={5} sm={6}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={
                                    this.state.viewActive === "active"
                                      ? true
                                      : false
                                  }
                                  id="defaultCheck1"
                                  onChange={(e) => {
                                    this.displayActv(e.target.checked);
                                  }} //e.target.checked   }}  e.target.checked === true ?   this.setState({  viewActive:'active'}) : this.setState({  viewActive:''}) ; this.fetchGenTgDt(this.state.prdtct,this.state.productCategory)
                                />
                                &nbsp;&nbsp;
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck1"
                                >
                                  View Active
                                </label>
                              </Col>

                              <Col
                                style={{ display: "-webkit-inline-box" }}
                                xl={3}
                                sm={6}
                              >
                                <input
                                  type="search"
                                  id="form1"
                                  placeholder="Search By Tag Number"
                                  className="form-control"
                                  name="searchkey"
                                  value={this.state.searchkey}
                                  onChange={this.changeHandler}
                                />

                                <Button
                                  onClick={() => {
                                    this.exlfetchGenTgDt(
                                      this.state.prdtct,
                                      this.state.productCategory,
                                      this.state.viewActive
                                    );
                                  }}
                                  style={{ marginLeft: "0.2rem" }}
                                  color="primary"
                                  className="font-16 btn-block btn btn-primary"
                                >
                                  Export CSV
                                </Button>
                              </Col>
                            </Row>
                            <BootstrapTable
                              keyField="id"
                              data={this.state.genTag}
                              columns={this.state.columnsGenlfTag}
                              pagination={paginationFactory()}
                            />
                          </div>
                        </>
                      )}
                      {this.state.activeTbl === 11 && (
                        <>
                          <div className="table-responsive">
                            <Row style={{ margin: "1rem" }}>
                              <Col style={{ display: "flex" }} xl={3} sm={6}>
                                <Flatpickr
                                  className="form-control d-block"
                                  placeholder="Select Range"
                                  options={{
                                    mode: "range",
                                    dateFormat: "Y-m-d",
                                  }}
                                  onChange={(date) => {
                                    this.setState({
                                      dateRange: false,
                                      selectedRange: date,
                                    });
                                  }}
                                  id="dtRngPick"
                                />
                                {this.state.dateRange ? (
                                  <Button
                                    onClick={() => {
                                      this.fetchGenTgDt(
                                        this.state.prdtct,
                                        this.state.productCategory,
                                        this.state.viewActive
                                      );
                                      this.setState({ selectedRange: [] });
                                      window.location.reload();
                                    }}
                                    style={{ marginLeft: "0.2rem" }}
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    Reset
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      this.fetchBasedOnDtRange();
                                    }}
                                    style={{ marginLeft: "0.2rem" }}
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    Apply
                                  </Button>
                                )}
                              </Col>
                              <Col xl={5} sm={6}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={
                                    this.state.viewActive === "active"
                                      ? true
                                      : false
                                  }
                                  id="defaultCheck1"
                                  onChange={(e) => {
                                    this.displayActv(e.target.checked);
                                  }} //e.target.checked   }}  e.target.checked === true ?   this.setState({  viewActive:'active'}) : this.setState({  viewActive:''}) ; this.fetchGenTgDt(this.state.prdtct,this.state.productCategory)
                                />
                                &nbsp;&nbsp;
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck1"
                                >
                                  View Active
                                </label>
                              </Col>

                              <Col
                                style={{ display: "-webkit-inline-box" }}
                                xl={3}
                                sm={6}
                              >
                                <input
                                  type="search"
                                  id="form1"
                                  placeholder="Search By Tag Number"
                                  className="form-control"
                                  name="searchkey"
                                  value={this.state.searchkey}
                                  onChange={this.changeHandler}
                                />

                                <Button
                                  onClick={() => {
                                    this.exlfetchGenTgDt(
                                      this.state.prdtct,
                                      this.state.productCategory,
                                      this.state.viewActive
                                    );
                                  }}
                                  style={{ marginLeft: "0.2rem" }}
                                  color="primary"
                                  className="font-16 btn-block btn btn-primary"
                                >
                                  Export CSV
                                </Button>
                              </Col>
                            </Row>
                            <BootstrapTable
                              keyField="id"
                              data={this.state.genTag}
                              columns={this.state.columnsGenTag}
                              pagination={paginationFactory()}
                            />
                          </div>
                        </>
                      )}
                      {/*______ E-Passport ______ */}
                      {this.state.activeTbl === 12 && (
                        <>
                          <div className="table-responsive">
                            <Row style={{ margin: "1rem" }}>
                              <Col style={{ display: "flex" }} xl={3} sm={6}>
                                <Flatpickr
                                  className="form-control d-block"
                                  placeholder="Select Range"
                                  options={{
                                    mode: "range",
                                    dateFormat: "Y-m-d",
                                  }}
                                  onChange={(date) => {
                                    this.setState({
                                      dateRange: false,
                                      selectedRange: date,
                                    });
                                  }}
                                  id="dtRngPick"
                                />
                                {this.state.dateRange ? (
                                  <Button
                                    onClick={() => {
                                      this.fetchGenTgDt(
                                        this.state.prdtct,
                                        this.state.productCategory,
                                        this.state.viewActive
                                      );
                                      this.setState({ selectedRange: [] });
                                      window.location.reload();
                                    }}
                                    style={{ marginLeft: "0.2rem" }}
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    Reset
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      this.fetchBasedOnDtRange();
                                    }}
                                    style={{ marginLeft: "0.2rem" }}
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    Apply
                                  </Button>
                                )}
                              </Col>
                              <Col xl={5} sm={6}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={
                                    this.state.viewActive === "active"
                                      ? true
                                      : false
                                  }
                                  id="defaultCheck1"
                                  onChange={(e) => {
                                    this.displayActv(e.target.checked);
                                  }} //e.target.checked   }}  e.target.checked === true ?   this.setState({  viewActive:'active'}) : this.setState({  viewActive:''}) ; this.fetchGenTgDt(this.state.prdtct,this.state.productCategory)
                                />
                                &nbsp;&nbsp;
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck1"
                                >
                                  View Active
                                </label>
                              </Col>

                              <Col
                                style={{ display: "-webkit-inline-box" }}
                                xl={3}
                                sm={6}
                              >
                                <input
                                  type="search"
                                  id="form1"
                                  placeholder="Search By Tag Number"
                                  className="form-control"
                                  name="searchkey"
                                  value={this.state.searchkey}
                                  onChange={this.changeHandler}
                                />

                                <Button
                                  onClick={() => {
                                    this.exlfetchGenTgDt(
                                      this.state.prdtct,
                                      this.state.productCategory,
                                      this.state.viewActive
                                    );
                                  }}
                                  style={{ marginLeft: "0.2rem" }}
                                  color="primary"
                                  className="font-16 btn-block btn btn-primary"
                                >
                                  Export CSV
                                </Button>
                              </Col>
                            </Row>
                            <BootstrapTable
                              keyField="id"
                              data={this.state.genTag}
                              columns={this.state.columnsGenTag}
                              pagination={paginationFactory()}
                            />
                          </div>
                        </>
                      )}
                      {/*______ E-Passport ______ */}

                      {/*______ E-Bag ______ */}
                      {this.state.activeTbl === 13 && (
                        <>
                          <div className="table-responsive">
                            <Row style={{ margin: "1rem" }}>
                              <Col style={{ display: "flex" }} xl={3} sm={6}>
                                <Flatpickr
                                  className="form-control d-block"
                                  placeholder="Select Range"
                                  options={{
                                    mode: "range",
                                    dateFormat: "Y-m-d",
                                  }}
                                  onChange={(date) => {
                                    this.setState({
                                      dateRange: false,
                                      selectedRange: date,
                                    });
                                  }}
                                  id="dtRngPick"
                                />
                                {this.state.dateRange ? (
                                  <Button
                                    onClick={() => {
                                      this.fetchGenTgDt(
                                        this.state.prdtct,
                                        this.state.productCategory,
                                        this.state.viewActive
                                      );
                                      this.setState({ selectedRange: [] });
                                      window.location.reload();
                                    }}
                                    style={{ marginLeft: "0.2rem" }}
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    Reset
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      this.fetchBasedOnDtRange();
                                    }}
                                    style={{ marginLeft: "0.2rem" }}
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    Apply
                                  </Button>
                                )}
                              </Col>
                              <Col xl={5} sm={6}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={
                                    this.state.viewActive === "active"
                                      ? true
                                      : false
                                  }
                                  id="defaultCheck1"
                                  onChange={(e) => {
                                    this.displayActv(e.target.checked);
                                  }} //e.target.checked   }}  e.target.checked === true ?   this.setState({  viewActive:'active'}) : this.setState({  viewActive:''}) ; this.fetchGenTgDt(this.state.prdtct,this.state.productCategory)
                                />
                                &nbsp;&nbsp;
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck1"
                                >
                                  View Active
                                </label>
                              </Col>

                              <Col
                                style={{ display: "-webkit-inline-box" }}
                                xl={3}
                                sm={6}
                              >
                                <input
                                  type="search"
                                  id="form1"
                                  placeholder="Search By Tag Number"
                                  className="form-control"
                                  name="searchkey"
                                  value={this.state.searchkey}
                                  onChange={this.changeHandler}
                                />

                                <Button
                                  onClick={() => {
                                    this.exlfetchGenTgDt(
                                      this.state.prdtct,
                                      this.state.productCategory,
                                      this.state.viewActive
                                    );
                                  }}
                                  style={{ marginLeft: "0.2rem" }}
                                  color="primary"
                                  className="font-16 btn-block btn btn-primary"
                                >
                                  Export CSV
                                </Button>
                              </Col>
                            </Row>
                            <BootstrapTable
                              keyField="id"
                              data={this.state.genTag}
                              columns={this.state.columnsGenTag}
                              pagination={paginationFactory()}
                            />
                          </div>
                        </>
                      )}
                      {/*______ E-Bag ______ */}

                      {/*______ QR Passport ______ */}
                      {this.state.activeTbl === 14 && (
                        <>
                          <div className="table-responsive">
                            <Row style={{ margin: "1rem" }}>
                              <Col style={{ display: "flex" }} xl={3} sm={6}>
                                <Flatpickr
                                  className="form-control d-block"
                                  placeholder="Select Range"
                                  options={{
                                    mode: "range",
                                    dateFormat: "Y-m-d",
                                  }}
                                  onChange={(date) => {
                                    this.setState({
                                      dateRange: false,
                                      selectedRange: date,
                                    });
                                  }}
                                  id="dtRngPick"
                                />
                                {this.state.dateRange ? (
                                  <Button
                                    onClick={() => {
                                      this.fetchGenTgDt(
                                        this.state.prdtct,
                                        this.state.productCategory,
                                        this.state.viewActive
                                      );
                                      this.setState({ selectedRange: [] });
                                      window.location.reload();
                                    }}
                                    style={{ marginLeft: "0.2rem" }}
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    Reset
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      this.fetchBasedOnDtRange();
                                    }}
                                    style={{ marginLeft: "0.2rem" }}
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    Apply
                                  </Button>
                                )}
                              </Col>
                              <Col xl={5} sm={6}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={
                                    this.state.viewActive === "active"
                                      ? true
                                      : false
                                  }
                                  id="defaultCheck1"
                                  onChange={(e) => {
                                    this.displayActv(e.target.checked);
                                  }} //e.target.checked   }}  e.target.checked === true ?   this.setState({  viewActive:'active'}) : this.setState({  viewActive:''}) ; this.fetchGenTgDt(this.state.prdtct,this.state.productCategory)
                                />
                                &nbsp;&nbsp;
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck1"
                                >
                                  View Active
                                </label>
                              </Col>

                              <Col
                                style={{ display: "-webkit-inline-box" }}
                                xl={3}
                                sm={6}
                              >
                                <input
                                  type="search"
                                  id="form1"
                                  placeholder="Search By Tag Number"
                                  className="form-control"
                                  name="searchkey"
                                  value={this.state.searchkey}
                                  onChange={this.changeHandler}
                                />

                                <Button
                                  onClick={() => {
                                    this.exlfetchGenTgDt(
                                      this.state.prdtct,
                                      this.state.productCategory,
                                      this.state.viewActive
                                    );
                                  }}
                                  style={{ marginLeft: "0.2rem" }}
                                  color="primary"
                                  className="font-16 btn-block btn btn-primary"
                                >
                                  Export CSV
                                </Button>
                              </Col>
                            </Row>
                            <BootstrapTable
                              keyField="id"
                              data={this.state.genTag}
                              columns={this.state.columnsGenTag}
                              pagination={paginationFactory()}
                            />
                          </div>
                        </>
                      )}
                      {/*______ QR Passport ______ */}

                      {/*______ QR Human ______ */}
                      {this.state.activeTbl === 15 && (
                        <>
                          <div className="table-responsive">
                            <Row style={{ margin: "1rem" }}>
                              <Col style={{ display: "flex" }} xl={3} sm={6}>
                                <Flatpickr
                                  className="form-control d-block"
                                  placeholder="Select Range"
                                  options={{
                                    mode: "range",
                                    dateFormat: "Y-m-d",
                                  }}
                                  onChange={(date) => {
                                    this.setState({
                                      dateRange: false,
                                      selectedRange: date,
                                    });
                                  }}
                                  id="dtRngPick"
                                />
                                {this.state.dateRange ? (
                                  <Button
                                    onClick={() => {
                                      this.fetchGenTgDt(
                                        this.state.prdtct,
                                        this.state.productCategory,
                                        this.state.viewActive
                                      );
                                      this.setState({ selectedRange: [] });
                                      window.location.reload();
                                    }}
                                    style={{ marginLeft: "0.2rem" }}
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    Reset
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      this.fetchBasedOnDtRange();
                                    }}
                                    style={{ marginLeft: "0.2rem" }}
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    Apply
                                  </Button>
                                )}
                              </Col>
                              <Col xl={5} sm={6}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={
                                    this.state.viewActive === "active"
                                      ? true
                                      : false
                                  }
                                  id="defaultCheck1"
                                  onChange={(e) => {
                                    this.displayActv(e.target.checked);
                                  }} //e.target.checked   }}  e.target.checked === true ?   this.setState({  viewActive:'active'}) : this.setState({  viewActive:''}) ; this.fetchGenTgDt(this.state.prdtct,this.state.productCategory)
                                />
                                &nbsp;&nbsp;
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck1"
                                >
                                  View Active
                                </label>
                              </Col>

                              <Col
                                style={{ display: "-webkit-inline-box" }}
                                xl={3}
                                sm={6}
                              >
                                <input
                                  type="search"
                                  id="form1"
                                  placeholder="Search By Tag Number"
                                  className="form-control"
                                  name="searchkey"
                                  value={this.state.searchkey}
                                  onChange={this.changeHandler}
                                />

                                <Button
                                  onClick={() => {
                                    this.exlfetchGenTgDt(
                                      this.state.prdtct,
                                      this.state.productCategory,
                                      this.state.viewActive
                                    );
                                  }}
                                  style={{ marginLeft: "0.2rem" }}
                                  color="primary"
                                  className="font-16 btn-block btn btn-primary"
                                >
                                  Export CSV
                                </Button>
                              </Col>
                            </Row>
                            <BootstrapTable
                              keyField="id"
                              data={this.state.genTag}
                              columns={this.state.columnsGenTag}
                              pagination={paginationFactory()}
                            />
                          </div>
                        </>
                      )}
                      {/*______ QR Human ______ */}

                      {/*______ QR Vehicle ______ */}
                      {this.state.activeTbl === 16 && (
                        <>
                          <div className="table-responsive">
                            <Row style={{ margin: "1rem" }}>
                              <Col style={{ display: "flex" }} xl={3} sm={6}>
                                <Flatpickr
                                  className="form-control d-block"
                                  placeholder="Select Range"
                                  options={{
                                    mode: "range",
                                    dateFormat: "Y-m-d",
                                  }}
                                  onChange={(date) => {
                                    this.setState({
                                      dateRange: false,
                                      selectedRange: date,
                                    });
                                  }}
                                  id="dtRngPick"
                                />
                                {this.state.dateRange ? (
                                  <Button
                                    onClick={() => {
                                      this.fetchGenTgDt(
                                        this.state.prdtct,
                                        this.state.productCategory,
                                        this.state.viewActive
                                      );
                                      this.setState({ selectedRange: [] });
                                      window.location.reload();
                                    }}
                                    style={{ marginLeft: "0.2rem" }}
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    Reset
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      this.fetchBasedOnDtRange();
                                    }}
                                    style={{ marginLeft: "0.2rem" }}
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    Apply
                                  </Button>
                                )}
                              </Col>
                              <Col xl={5} sm={6}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={
                                    this.state.viewActive === "active"
                                      ? true
                                      : false
                                  }
                                  id="defaultCheck1"
                                  onChange={(e) => {
                                    this.displayActv(e.target.checked);
                                  }} //e.target.checked   }}  e.target.checked === true ?   this.setState({  viewActive:'active'}) : this.setState({  viewActive:''}) ; this.fetchGenTgDt(this.state.prdtct,this.state.productCategory)
                                />
                                &nbsp;&nbsp;
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck1"
                                >
                                  View Active
                                </label>
                              </Col>

                              <Col
                                style={{ display: "-webkit-inline-box" }}
                                xl={3}
                                sm={6}
                              >
                                <input
                                  type="search"
                                  id="form1"
                                  placeholder="Search By Tag Number"
                                  className="form-control"
                                  name="searchkey"
                                  value={this.state.searchkey}
                                  onChange={this.changeHandler}
                                />

                                <Button
                                  onClick={() => {
                                    this.exlfetchGenTgDt(
                                      this.state.prdtct,
                                      this.state.productCategory,
                                      this.state.viewActive
                                    );
                                  }}
                                  style={{ marginLeft: "0.2rem" }}
                                  color="primary"
                                  className="font-16 btn-block btn btn-primary"
                                >
                                  Export CSV
                                </Button>
                              </Col>
                            </Row>
                            <BootstrapTable
                              keyField="id"
                              data={this.state.genTag}
                              columns={this.state.columnsGenTag}
                              pagination={paginationFactory()}
                            />
                          </div>
                        </>
                      )}
                      {/*______ QR Vehicle ______ */}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
          )}
          </div>
        <Dialog
          fullScreen
          open={this.state.qrmodal}
          onClose={() => {
            this.setState({ qrmodal: false });
          }}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  this.setState({ qrmodal: false });
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Qr Generation
              </Typography>
              <Button
                autoFocus
                style={{ color: "white" }}
                onClick={() => {
                  this.setState({ qrmodal: false });
                }}
              >
                close
              </Button>
            </Toolbar>
          </AppBar>
          <div
            style={{
              display: "flex",
              margin: "1rem",
              marginTop: "5rem",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Col style={{ display: "flex" }} xl={6} sm={6}>
              <input
                type="text"
                value={this.state.qrvalue}
                id="form1"
                placeholder="enter the url"
                className="form-control"
                name="searchkey"
                onChange={(e) => {
                  this.setState({ qrvalue: e?.target?.value });
                }}
              />
            </Col>
            <div>
              <Button
                style={{ marginTop: "3rem" }}
                onClick={this.generateqrApi}
                variant="contained"
                color="success"
              >
                Generate
              </Button>
            </div>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

TagsList.propTypes = {
  users: PropTypes.array,
  className: PropTypes.any,
  onGetUsers: PropTypes.func,
  onAddNewUser: PropTypes.func,
  onDeleteUser: PropTypes.func,
  onUpdateUser: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
  onAddNewUser: (user) => dispatch(addNewUser(user)),
  onUpdateUser: (user) => dispatch(updateUser(user)),
  onDeleteUser: (user) => dispatch(deleteUser(user)),
});

// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(withRouter(TagsList));

export default TagsList;
