import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import BarcodeReader from "react-barcode-reader";
import BootstrapTable from "react-bootstrap-table-next";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import QrReader from "react-qr-scanner";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { v4 as uuid } from "uuid";
import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { BASE_URL } from "config";
import MenuItem from "@mui/material/MenuItem";
import paginationFactory from "react-bootstrap-table2-paginator";
import MUIDataTable from "mui-datatables";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { QrScanner } from "@yudiel/react-qr-scanner";
import axiosInstance from "Axiosinstance";
import SettingsIcon from "@mui/icons-material/Settings";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import {
  FormControlLabel,
  FormLabel,
  Link,
  Radio,
  RadioGroup,
} from "@mui/material";
import Loader from "components/Loader";
import { Col } from "reactstrap";
import Chip from "@mui/material/Chip";
import { local } from "utils/Constant";

function Macpairing() {
  const unique_id = uuid();
  const [dispatchCode,setdispatchCode] = useState('')
  const [dispatchModal,setdispatchModal] = useState(false)
  const [scaner, handelscaner] = useState(false);
  const [tagno, settagno] = useState("");
  const [macid, setmacid] = useState("");
  const [product, setproduct] = useState("");
  const [productcolor, setcolour] = useState("");
  const [tagdata, settagdata] = useState([]);
  const [totalscan, settotalscan] = useState(0);
  const [macqr, setmacqr] = useState(false);
  const [qrerror, setqrerror] = useState(false);
  const [companyname, setcompanyname] = useState("");
  const [tagdesignvalue,settagdesignvalue] = useState("")
  // eslint-disable-next-line no-unused-expressions
  const [width, setWidth] = useState(window.innerWidth);
  const [qrerror2, setqrerror2] = useState(false);
  const [producttype, setproducttype] = useState("");
  const [subproduct, setsubproduct] = useState("");
  const [subproductcolor, setsubproductcolor] = useState("");
  const [result, setresult] = useState([]);

  const [allcatg, setallcatg] = useState([]);
  const [pairingmodal, setpairingmodal] = useState(false);
  const [settingsmodal, setsettingsmodal] = useState(false);
  const [field, setfield] = useState({});
  const [loader, setloader] = useState(false);
  const [producttypefield, setproducttypefield] = useState([]);
  const [producttypecolor, setproducttypecolor] = useState([]);
  const [subproducttype, setsubproducttype] = useState([]);
  const [subproducttypecolor, setsubproducttypecolor] = useState([]);
  const [searchkey, setsearchkey] = useState("");
  const [userrole, setuserrole] = useState("");
  const [datefilter, setdatefilter] = useState({
    from: "",
    to: "",
  });
  const [addmodal, setaddmodal] = useState({
    open: false,
    type: "",
  });
  const [deletemodal, setdeletemodal] = useState({
    open: false,
    type: "",
    _id: "",
  });
  const [editmodal, seteditmodal] = useState({
    open: false,
    type: "",
    _id: "",
    category_map: new Set(),
  });
  const [skiptagnumber, setskiptagnumber] = useState(false);
  const [customername, setcustomername] = useState([]);
  const [tagdesign,settagdesign] = useState([])

  const [dependency, setdependency] = useState("");
  const [categories, setcategories] = useState(new Set());

  const [editdependency, seteditdependency] = useState("");

  const adddependency = (e) => {
    setdependency(e?.target?.value);
    setcategories((prev) => new Set(prev).add(e?.target?.value));
    console.log(categories);
  };

  const addeditdependency = (e) => {
    const newSet = new Set(editmodal?.category_map).add(e?.target?.value);
    seteditdependency(e?.target?.value);
    seteditmodal({
      ...editmodal,
      category_map: newSet,
    });
  };
  const removeCatg = (e) => {
    console.log(e);
    const newSet = new Set(editmodal?.category_map);
    newSet.delete(e);
    seteditmodal({
      ...editmodal,
      category_map: newSet,
    });
  };
  const deletecatg = (e) => {
    setcategories((prev) => {
      const newSet = new Set(prev);
      newSet.delete(e);
      return newSet;
    });
  };

  const handelreset = () => {
    setproduct("");
    settagno("");
    setmacid("");
    setproducttype("");
    setcolour("");
    setcompanyname("");
    setsubproduct("");
    setsubproductcolor("");
    settotalscan(0);
    settagdesignvalue("")
    setskiptagnumber(false);
    setpairingmodal(false);
    settagdata([]);
  };

  const clearalloptions = () => {
    setproduct("");
    settagno("");
    setmacid("");
    setproducttype("");
    setcolour("");
    setcompanyname("");
    setsubproduct("");
    setsubproductcolor("");
    setskiptagnumber(false);
  };

  const changecategory = (e) => {
    clearalloptions();
    setproduct(e?.target?.value);
  };
  const handlecsv = async (e) => {
    try {
      if (!e.target.files) {
        return;
      }
      let data = new FormData();
      data.append("file", e.target.files[0]);
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `/api/csvmacpairing`,
        data: data,
      };
      axiosInstance.request(config).then(({ data }) => {
        window.location.reload();
      });
    } catch (error) {}
  };

  const checkingUserrole = () => {
    try {
      const user = localStorage.getItem("user");

      if (user !== null) {
        const userInfo = JSON.parse(user);
        setuserrole(userInfo?.role);
      }
    } catch (er) {}
  };
  const handeldeletemodal = (type, id) => {
    try {
      //console.log("clickedddddddd");
      setdeletemodal({
        ...deletemodal,
        open: true,
        type: type,
        _id: id,
      });
    } catch (er) {
      //console.log(er);
    }
  };
  const handeladdmodal = (type) => {
    try {
      setaddmodal({ ...addmodal, open: true, type: type });
    } catch (error) {}
  };
  const handeldate = (e) => {
    const datename = e?.target?.name;
    setdatefilter({ ...datefilter, [e?.target?.name]: e?.target?.value });
  };
  const sortdateApi = async (date) => {
    try {
      //console.log(date);
      if (Object.keys(datefilter).length === 2) {
        const { data } = await axiosInstance.post(`/api/sortdate`, {
          datefilter,
          type: "macpairinglogs",
        });
        //console.log(data);
        if (data?.status) {
          setresult(data?.maclogs);
        }
      }
    } catch (error) {}
  };
  const handeleditmodal = (type, value, id, categorymap) => {
    if (categorymap) {
      const newcategorySet = new Set(categorymap);

      seteditmodal({
        ...editmodal,
        open: true,
        type: type,
        _id: id,
        category_map: newcategorySet,
      });
    } else {
      seteditmodal({ ...editmodal, open: true, type: type, _id: id });
    }

    setfield({ ...field, [type]: value });
  };
  const getTagdesign = async ()=>{
    try{
const {data} = await axiosInstance.get("/api/gettagdesign")
  settagdesign(data?.tagdesign)
    }
    catch(error){
 setloader(false)
    }
  }
  const getallCustomer = async () => {
    try {
      const { data } = await axiosInstance.get("/api/getcustomername");
      if (data?.status) {
        setcustomername(data?.data);
      } else {
        setloader(false);
      }
    } catch (er) {
      setloader(false);
    }
  };
  const getallfields = async () => {
    try {
      setloader(true);
      const { data } = await axiosInstance.get("/api/getallMacfields");
      if (data?.status) {
        setproducttypefield(data?.productType);
        setproducttypecolor(data?.productTypecolor);
        setsubproducttype(data?.subproductType);
        setsubproducttypecolor(data?.subproductTypecolor);
        getallCustomer();
        getTagdesign();
        setloader(false);
      } else {
        setloader(false);
      }
    } catch (error) {
      //console.log(error);
      setloader(false);
    }
  };
  const handelcancel = (type) => {
    if (type === "add") {
      setaddmodal({ ...addmodal, open: false, type: "" });
      setfield({});
      setcategories(new Set());
      setdependency("");
    } else if (type === "edit") {
      seteditmodal({ ...editmodal, open: false, type: "", _id: "" });
      setfield({});
    } else if (type === "delete") {
      setdeletemodal({ ...deletemodal, open: false, type: "", _id: "" });
    }
  };
  const handelchange = (e) => {
    setfield({ ...field, [e?.target?.name]: e?.target?.value });
  };
  const handelsearchinput = (e) => {
    setsearchkey(e.target.value);
  };
  const handelfieldsubmit = async (
    e,
    type,
    operation,
    id,
    categoryMap = []
  ) => {
    try {
      e?.preventDefault();
      //console.log(field);

      if (Object.keys(field).length || operation === "delete") {
        if (operation === "add") {
          setloader(true);
          if (type === "Customer Name") {
            const { data } = await axiosInstance.post("/api/addcustomername", {
              type,
              value: field[type],
            });
            if (data?.status) {
              getallfields();
              setaddmodal({ ...addmodal, open: false, type: "" });
              setfield({});
              setloader(false);
            } else {
              setaddmodal({ ...addmodal, open: false, type: "" });
              setfield({});
              setloader(false);

              alert(data?.message || "Failed");
            }
          } 
          else if(type === "Tag Design"){
            const {data} = await axiosInstance.post("/api/addtagdesign",{
              type,
              value: field[type],
            })
            if(data?.status){
              getallfields();
              setaddmodal({ ...addmodal, open: false, type: "" });
              setfield({});
              setloader(false);
            }
            else{
              getallfields();
              setaddmodal({ ...addmodal, open: false, type: "" });
              setfield({});
              setloader(false);
            }
          }
          else {
            const { data } = await axiosInstance.post(
              "/api/addmacpairingfields",
              {
                type,
                value: field[type],
                categoryMap: [...categories],
              }
            );

            if (data?.status) {
              getallfields();
              setaddmodal({ ...addmodal, open: false, type: "" });
              setfield({});
              setloader(false);
              setdependency("");
              setcategories(new Set());
            } else {
              getallfields();
              setaddmodal({ ...addmodal, open: false, type: "" });
              setfield({});
              setloader(false);

              alert(data?.message || "Failed");
            }
          }
        }
        else if(operation === "edit" && type === "Tag Design"){
          const {data} = await axiosInstance.post("/api/edittagdesign", {
            type,
            value: field[type],
            id,
            categoryMap: [...categoryMap],
          })
          if(data?.status){
            getallfields();
            setfield({});
            seteditmodal({
              ...editmodal,
              open: false,
              type: "",
              _id: "",
              category_map: new Set(),
            });
            setloader(false);
          }
        } 
        else if(operation === "edit" && type === "Customer Name"){
          const {data} = await axiosInstance.post("/api/editcustomername",{
            type,
            value: field[type],
            id,
            categoryMap: [...categoryMap],
          })
          if (data?.status) {
            getallfields();
            setfield({});
            seteditmodal({
              ...editmodal,
              open: false,
              type: "",
              _id: "",
              category_map: new Set(),
            });
            setloader(false);
          } else {
            setloader(false);
            setfield({});
            alert(data?.message || "Failed");
            seteditmodal({
              ...editmodal,
              open: false,
              type: "",
              _id: "",
              category_map: new Set(),
            });
          }
        }
        else if (operation === "edit" && field[type].length) {
          setloader(true);
          const { data } = await axiosInstance.post(
            "/api/editmacpairingfields",
            {
              type,
              value: field[type],
              id,
              categoryMap: [...categoryMap],
            }
          );
          if (data?.status) {
            getallfields();
            setfield({});
            seteditmodal({
              ...editmodal,
              open: false,
              type: "",
              _id: "",
              category_map: new Set(),
            });
            setloader(false);
          } else {
            setloader(false);
            setfield({});
            alert(data?.message || "Failed");
            seteditmodal({
              ...editmodal,
              open: false,
              type: "",
              _id: "",
              category_map: new Set(),
            });
          }
        }
        else if(operation === "delete" && type === "Tag Design"){
          const {data} = await axiosInstance.post("/api/deletetagdesign", {
            type,
            id,
          })
          if (data?.status) {
            getallfields();
            setdeletemodal({ ...deletemodal, open: false, type: "", _id: "" });
            setloader(false);
          } else {
            setdeletemodal({ ...deletemodal, open: false, type: "", _id: "" });
            setloader(false);
            alert(data?.message || "unexpected error occured");
          }
        } 
        else if(operation === "delete" && type === "Customer Name"){
          const {data} = await axiosInstance.post("/api/deletecustomername",{
            type,
            id
          })
          if (data?.status) {
            getallfields();
            setdeletemodal({ ...deletemodal, open: false, type: "", _id: "" });
            setloader(false);
          } else {
            setdeletemodal({ ...deletemodal, open: false, type: "", _id: "" });
            setloader(false);
            alert(data?.message || "unexpected error occured");
          }
        }
        else if (operation === "delete") {
          setloader(true);
          const { data } = await axiosInstance.post(
            "/api/deletemacpairingfields",
            {
              type,
              id,
            }
          );
          if (data?.status) {
            getallfields();
            setdeletemodal({ ...deletemodal, open: false, type: "", _id: "" });
            setloader(false);
          } else {
            setdeletemodal({ ...deletemodal, open: false, type: "", _id: "" });
            setloader(false);
            alert(data?.message || "unexpected error occured");
          }
        }
      }
    } catch (error) {
      //console.log(error);
      setaddmodal({ ...addmodal, open: false, type: "" });
      seteditmodal({ ...editmodal, open: false, type: "", _id: "" });
      setdeletemodal({ ...deletemodal, open: false, type: "", _id: "" });
      setfield({});
      setloader(false);
      alert( error?.response?.data?.message || "unexpected error ocured");
    }
  };
  let isDesktop = width > 768;

  const fetchLogs = async () => {
    try {
      setloader(true);
      const { data } = await axiosInstance.get(`/api/fetchmacpairinglogs`);
      setresult(data?.allLogs);
      setloader(false);
    } catch (error) {
      setloader(false);
      //console.log(error);
    }
  };
  const fetchallCategory = async () => {
    try {
      const { data } = await axiosInstance.get(`/api/getallcategories`);
      setallcatg(data?.allcategories);
      getTagdesign()
    } catch (er) {
      //console.log(er);
    }
  };
  const handelclose = () => {
    settagno("");
    setmacid("");
    setdispatchModal(false)
    setqrerror(false);
    setqrerror2(false);

    setmacqr(false);
    handelscaner(false);
  };

  const handelsubmit = () => {
    setloader(true);
    axiosInstance
      .post(`/api/macpairing`, { tagdata })
      .then(({ data }) => {
        //console.log(data);
        settagdata([]);
        setloader(false);
        setpairingmodal(false);
        setproduct("");
        setsubproduct("");
        setproducttype("");
        setsubproductcolor("");
        setcolour("");
        setcompanyname("");
        fetchLogs();
        settotalscan(0);
        settagdesignvalue("")
      })
      .catch((er) => {
        settotalscan(0);
        setloader(false);
        settagdata([]);
        setpairingmodal(false);
        alert("unexpected error ocured");
        setproduct("");
        setproducttype("");
        setsubproduct("");
        setsubproductcolor("");
        setcolour("");
      });
    settagno("");
    setmacid("");
    setmacqr(false);
  };

  const column4 = [
    {
      dataField: "_id",
      text: "",
      formatter: (cell, row, rowIndex) => rowIndex + 1, // Display row number starting from 1
      headerStyle: () => {
        return { width: "50px" }; // Adjust the width as needed
      },
    },
    {
      dataField: "field",
      text: "Hardware Colour",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product.value ? (
              <Link to="#" className="text-dark">
                {product?.value}
              </Link>
            ) : (
              <Link to="#" className="text-dark">
                <p> No </p>
              </Link>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "value",
      text: "Edit",
      formatter: (cellContent, product) => {
        return (
          <div className="d-flex gap-3">
            <>
              <Link
                onClick={() => {
                  handeleditmodal(
                    "Hardware Colour",
                    product?.value,
                    product?._id
                  );
                }}
                className="text-success"
                to="#"
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
              </Link>
            </>
          </div>
        );
      },
    },
    {
      dataField: "value",
      text: "Delete",
      formatter: (cellContent, product) => {
        return (
          <div className="d-flex gap-3">
            <>
              <Link
                onClick={() => {
                  handeldeletemodal("Hardware Colour", product?._id);
                }}
                className="text-danger"
                to="#"
              >
                <i className="mdi mdi-delete font-size-18" id="edittooltip"></i>
              </Link>
            </>
          </div>
        );
      },
    },
  ];

  const column3 = [
    {
      dataField: "_id",
      text: "",
      formatter: (cell, row, rowIndex) => rowIndex + 1, // Display row number starting from 1
      headerStyle: () => {
        return { width: "50px" }; // Adjust the width as needed
      },
    },
    {
      dataField: "field",
      text: "Embeded Hardware",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product.value ? (
              <Link to="#" className="text-dark">
                {product?.value}
              </Link>
            ) : (
              <Link to="#" className="text-dark">
                <p> No </p>
              </Link>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "value",
      text: "Edit",
      formatter: (cellContent, product) => {
        return (
          <div className="d-flex gap-3">
            <>
              <Link
                onClick={() => {
                  handeleditmodal(
                    "Embeded Hardware",
                    product?.value,
                    product?._id
                  );
                }}
                className="text-success"
                to="#"
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
              </Link>
            </>
          </div>
        );
      },
    },
    {
      dataField: "value",
      text: "Delete",
      formatter: (cellContent, product) => {
        return (
          <div className="d-flex gap-3">
            <>
              <Link
                onClick={() => {
                  handeldeletemodal("Embeded Hardware", product?._id);
                }}
                className="text-danger"
                to="#"
              >
                <i className="mdi mdi-delete font-size-18" id="edittooltip"></i>
              </Link>
            </>
          </div>
        );
      },
    },
  ];

  const column2 = [
    {
      dataField: "_id",
      text: "",
      formatter: (cell, row, rowIndex) => rowIndex + 1, // Display row number starting from 1
      headerStyle: () => {
        return { width: "50px" }; // Adjust the width as needed
      },
    },
    {
      dataField: "field",
      text: "Product Colour",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product.value ? (
              <Link to="#" className="text-dark">
                {product?.value}
              </Link>
            ) : (
              <Link to="#" className="text-dark">
                <p> No </p>
              </Link>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "value",
      text: "Edit",
      formatter: (cellContent, product) => {
        return (
          <div className="d-flex gap-3">
            <>
              <Link
                onClick={() => {
                  handeleditmodal(
                    "Product Colour",
                    product?.value,
                    product?._id
                  );
                }}
                className="text-success"
                to="#"
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
              </Link>
            </>
          </div>
        );
      },
    },
    {
      dataField: "value",
      text: "Delete",
      formatter: (cellContent, product) => {
        return (
          <div className="d-flex gap-3">
            <>
              <Link
                onClick={() => {
                  handeldeletemodal("Product Colour", product?._id);
                }}
                className="text-danger"
                to="#"
              >
                <i className="mdi mdi-delete font-size-18" id="edittooltip"></i>
              </Link>
            </>
          </div>
        );
      },
    },
  ];
  const column6 = [
    {
      dataField: "_id",
      text: "",
      formatter: (cell, row, rowIndex) => rowIndex + 1, // Display row number starting from 1
      headerStyle: () => {
        return { width: "50px" }; // Adjust the width as needed
      },
    },
    {
      dataField: "design_name",
      text: "Tag Design",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.design_name ? (
              <Link to="#" className="text-dark">
                {product?.design_name}
              </Link>
            ) : (
              <Link to="#" className="text-dark">
                <p> No </p>
              </Link>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "design_name",
      text: "Edit",
      formatter: (cellContent, product) => {
        return (
          <div className="d-flex gap-3">
            <>
              <Link
                onClick={() => {
                  handeleditmodal(
                    "Tag Design",
                    product?.design_name,
                    product?._id
                  );
                }}
                className="text-success"
                to="#"
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
              </Link>
            </>
          </div>
        );
      },
    },
    {
      dataField: "design_name",
      text: "Delete",
      formatter: (cellContent, product) => {
        return (
          <div className="d-flex gap-3">
            <>
              <Link
                onClick={() => {
                  handeldeletemodal("Tag Design", product?._id);
                }}
                className="text-danger"
                to="#"
              >
                <i className="mdi mdi-delete font-size-18" id="edittooltip"></i>
              </Link>
            </>
          </div>
        );
      },
    },
  ];
 

  const column5 = [
    {
      dataField: "_id",
      text: "",
      formatter: (cell, row, rowIndex) => rowIndex + 1, // Display row number starting from 1
      headerStyle: () => {
        return { width: "50px" }; // Adjust the width as needed
      },
    },
    {
      dataField: "field",
      text: "Customer Name",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.organisation_name ? (
              <Link to="#" className="text-dark">
                {product?.organisation_name}
              </Link>
            ) : (
              <Link to="#" className="text-dark">
                <p> No </p>
              </Link>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "organisation_name",
      text: "Edit",
      formatter: (cellContent, product) => {
        return (
          <div className="d-flex gap-3">
            <>
              <Link
                onClick={() => {
                  handeleditmodal(
                    "Customer Name",
                    product?.organisation_name,
                    product?._id
                  );
                }}
                className="text-success"
                to="#"
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
              </Link>
            </>
          </div>
        );
      },
    },
    {
      dataField: "organisation_name",
      text: "Delete",
      formatter: (cellContent, product) => {
        return (
          <div className="d-flex gap-3">
            <>
              <Link
                onClick={() => {
                  handeldeletemodal("Customer Name", product?._id);
                }}
                className="text-danger"
                to="#"
              >
                <i className="mdi mdi-delete font-size-18" id="edittooltip"></i>
              </Link>
            </>
          </div>
        );
      },
    },
  ];

  const column1 = [
    {
      dataField: "_id",
      text: "",
      formatter: (cell, row, rowIndex) => rowIndex + 1, // Display row number starting from 1
      headerStyle: () => {
        return { width: "50px" }; // Adjust the width as needed
      },
    },
    {
      dataField: "field",
      text: "Product",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product.value ? (
              <Link to="#" className="text-dark">
                {product?.value}
              </Link>
            ) : (
              <Link to="#" className="text-dark">
                <p> No </p>
              </Link>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "value",
      text: "Edit",
      formatter: (cellContent, product) => {
        return (
          <div className="d-flex gap-3">
            <>
              <Link
                onClick={() => {
                  handeleditmodal(
                    "Product",
                    product?.value,
                    product?._id,
                    product?.category_map
                  );
                }}
                className="text-success"
                to="#"
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
              </Link>
            </>
          </div>
        );
      },
    },
    {
      dataField: "value",
      text: "Delete",
      formatter: (cellContent, product) => {
        return (
          <div className="d-flex gap-3">
            <>
              <Link
                onClick={() => {
                  handeldeletemodal("Product", product?._id);
                }}
                className="text-danger"
                to="#"
              >
                <i className="mdi mdi-delete font-size-18" id="edittooltip"></i>
              </Link>
            </>
          </div>
        );
      },
    },
  ];

  const column = [
    {
      dataField: "_id",
      text: "",
      formatter: (cell, row, rowIndex) => rowIndex + 1, // Display row number starting from 1
      headerStyle: () => {
        return { width: "50px" }; // Adjust the width as needed
      },
    },
    {
      dataField: "lastscan_date",
      text: "Scanned On",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.lastscan_date ? (
              <a className="text-dark">
                {new Date(product.lastscan_date).toLocaleString()}
              </a>
            ) : (
              <a className="text-dark">
                <p> No </p>
              </a>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "tagnumber",
      text: "TagNumber",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.tagnumber ? (
              <a className="text-dark">{product.tagnumber}</a>
            ) : (
              <a className="text-dark">
                <p> No </p>
              </a>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "macaddress",
      text: "Mac address",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.macaddress ? (
              <a className="text-dark">{product.macaddress}</a>
            ) : (
              <a className="text-dark">
                <p> No </p>
              </a>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "productcategory",
      text: "Product Category",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.productcategory ? (
              <a className="text-dark">{product.productcategory}</a>
            ) : (
              <a className="text-dark">
                <p> No </p>
              </a>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "producttype",
      text: "Product",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.producttype ? (
              <a className="text-dark">{product.producttype}</a>
            ) : (
              <a className="text-dark">
                <p> No </p>
              </a>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "subproduct",
      text: "Embeded Hardware",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.subproduct ? (
              <a className="text-dark">{product.subproduct}</a>
            ) : (
              <a className="text-dark">
                <p> No </p>
              </a>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "productcolor",
      text: "Product Color",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.productcolor ? (
              <a className="text-dark">{product.productcolor}</a>
            ) : (
              <a className="text-dark">
                <p> No </p>
              </a>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "subproductcolor",
      text: "Hardware Color",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.subproductcolor ? (
              <a className="text-dark">{product.subproductcolor}</a>
            ) : (
              <a className="text-dark">
                <p> No </p>
              </a>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "customer_name",
      text: "Customer Name",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.customer_name ? (
              <a className="text-dark">{product.customer_name}</a>
            ) : (
              <a className="text-dark">
                <p> No </p>
              </a>
            )}
          </h5>
        );
      },
    },
    {
      text: "Created By", // Add this new column
      dataField: "created_by",
      sort: true,
      formatter: (cellContent, product) => (
        <>
          <h5 className="font-size-14 mb-1">
            {product.created_by ? product.created_by : "N/A"}
          </h5>
        </>
      ),
    },
    // {
    //   dataField: "uid",
    //   text: "UID",
    //   formatter: (cell) => cell || "N/A",
    // },
    {
      dataField: "dispatch_code",
      text: "Dispatch Code",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.dispatch_code? (
              <a className="text-dark">{product.dispatch_code}</a>
            ) : (
              <a className="text-dark">
                <p> No </p>
              </a>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "Status",
      text: "Status",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product.Status ? (
              <a
                style={{
                  color: product?.Status === "success" ? "green" : "red",
                }}
              >
                {product?.Status}
              </a>
            ) : (
              <a className="text-dark">
                <p> Nill </p>
              </a>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "message",
      text: "message",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            <a className="text-dark">
              <a
                style={{
                  color: product?.Status === "success" ? "green" : "red",
                }}
              >
                {product?.message}
              </a>
            </a>
          </h5>
        );
      },
    },
  ];
  function handleWindowSizeChange() {
    setWidth(window?.innerWidth);
  }
  useEffect(() => {
    if (!searchkey?.length) {
      fetchLogs();
      checkingUserrole();
    } else {
      const getsearchResult = setTimeout(() => {
        axiosInstance
          .post("/api/maclogsearch", { key: searchkey })
          .then((data) => {
            setresult(data?.data);
          })
          .catch((er) => {
            //console.log(er);
          });
      }, 1000);
      return () => clearTimeout(getsearchResult);
    }
  }, [searchkey]);

  const openscanner = () => {
    settagno("");
    setmacid("");
    setdispatchCode("")
    if (skiptagnumber) {
      {
        /*  

         const tagpair = {
        id: unique_id.slice(0, 8),
        macid: "54:55:9g:65:Ek:2g",
        product,
        producttype,
        productcolor,
        subproduct,
        subproductcolor,
        skiptagnumber,
        companyname,
      };

      if (
        tagpair?.id &&
        tagpair?.macid &&
        tagpair?.product &&
        tagpair?.productcolor &&
        tagpair?.subproduct &&
        tagpair?.subproductcolor &&
        tagpair?.producttype
      ) {
        settotalscan((prev) => prev + 1);
        settagdata((prevTagdata) => [...prevTagdata, tagpair]);
        setmacqr(false);
       
      }
         */
      }

      setmacqr(true);
    } else {
      if (product.slice(0, 2) === "QR" || product === "Generic") {
        {
          /*
              const tagpair = {
          id: unique_id.slice(0, 8),
          tagnumber: "TGQXP99kkk",
          product,

          companyname,
        };
         if (
          tagpair?.id &&
          tagpair?.product &&
          tagpair?.tagnumber &&
          tagpair?.product
        ) {
          settotalscan((prev) => prev + 1);
          settagdata((prevTagdata) => [...prevTagdata, tagpair]);
          setmacqr(false);
        }
        
           */
        }
      } else {
        /*
        const tagpair = {
          id: unique_id.slice(0, 8),
          tagnumber: "BDTFZU7",
          macid: "blublublublu",
          product,
          producttype,
          productcolor,
          subproduct,
          subproductcolor,
          skiptagnumber,
          companyname,
        };
        
        settotalscan((prev) => prev + 1);
        settagdata((prevTagdata) => [...prevTagdata, tagpair]);
        handelscaner(true);

        */
      }
      handelscaner(true);
    }
  };
  const dummpy = () => {
    settagno("BDT4I2A");
    handelscaner(false);
    if (product?.slice(0, 2) !== "QR" && product !== "Generic") {
      setmacqr(true);
    } else {
     setdispatchModal(true)
    }
  };
  const success = (result) => {
    //console.log("qr code scaned is :");
    console.log(result);
    if (result) {
      if (
        result.includes("https://dashboard.tag8.in/scan/") ||
        result.includes("https://dashboard.tag8.co.in/scan/") ||
        result.includes("https://dashboard.tag8.in/#/scan/")
      ) {
        let tagnumber;
        if (result.includes("https://dashboard.tag8.co.in/scan/")) {
          tagnumber = result.replace("https://dashboard.tag8.co.in/scan/", "");
        } else if (result.includes("https://dashboard.tag8.in/#/scan/")) {
          tagnumber = result.replace("https://dashboard.tag8.in/#/scan/", "");
        } else {
          tagnumber = result.replace("https://dashboard.tag8.in/scan/", "");
        }
        setqrerror(false);
        settagno(tagnumber);

        handelscaner(false);
        if (product?.slice(0, 2) !== "QR" && product !== "Generic") {
          setmacqr(true);
        } else {
          setdispatchModal(true)
      
        }
      } else {
       
        //console.log("reached invalid part");
        //console.log(result.includes("https://dashboard.tag8.in/#/scan/"));
        setqrerror(true);
      }
    }
  };
  const handelradiobutton = (e) => {
    //console.log(e?.target?.value)
    setskiptagnumber((prev) => !prev);
  };
  const dummac = () => {
    setmacid("4:82:39:35:26:F6");
    setmacqr(false)
    setdispatchModal(true)
  };
  const macscan = (result) => {
    //console.log(result);
    //console.log(result);

    const mcid = result;
    //console.log(mcid);
    if (mcid.length === 17) {
      setqrerror2(false);
      setmacid(result);
      setdispatchModal(true)
      setmacqr(false)
    /*  
      const tagpair = {
        id: unique_id.slice(0, 8),
        tagnumber: tagno,
        macid: result,
        product,
        producttype,
        productcolor,
        subproduct,
        subproductcolor,
        skiptagnumber,
        companyname,
      };

      //settagdata(prevTagdata => [...prevTagdata, tagpair]);
      if (skiptagnumber) {
        if (
          tagpair?.id &&
          tagpair?.macid &&
          tagpair?.product &&
          tagpair?.productcolor &&
          tagpair?.subproduct &&
          tagpair?.subproductcolor &&
          tagpair?.producttype
        ) {
          settotalscan((prev) => prev + 1);
          settagdata((prevTagdata) => [...prevTagdata, tagpair]);
          setmacqr(false);
          openscanner();
        }
      } else {
        if (
          tagpair?.id &&
          tagpair?.tagnumber &&
          tagpair?.macid &&
          tagpair?.product &&
          tagpair?.productcolor &&
          tagpair?.subproduct &&
          tagpair?.subproductcolor &&
          tagpair?.producttype
        ) {
          settotalscan((prev) => prev + 1);
          settagdata((prevTagdata) => [...prevTagdata, tagpair]);
          setmacqr(false);
          openscanner();
        }
      }
     */
    } else {
      setqrerror2(true);
    }
  };
  const error = (er) => {
    //console.log(er);
  };
  const dispatchScan = (result)=>{
    setdispatchCode(result)
    if(product?.slice(0, 2) === "QR" || product === "Generic"){
      const tagpair = {
        id: unique_id.slice(0, 8),
        dispatchcode : result,
        tagnumber: tagno,
        product,
        producttype,
        productcolor,
        companyname,
        tagdesignvalue
      };
      if( tagpair?.id &&
        tagpair?.tagnumber &&
        tagpair?.product &&
        tagpair?.productcolor &&
        tagpair?.producttype &&
        tagpair?.dispatchcode &&
        tagpair?.tagdesignvalue
      ){
        settotalscan((prev) => prev + 1);
        settagdata((prevTagdata) => [...prevTagdata, tagpair]);
        setdispatchModal(false);
        openscanner();
  
        }

    }
    else{

      const tagpair = {
        id: unique_id.slice(0, 8),
        dispatchcode : result,
        tagnumber: tagno,
        macid: macid,
        product,
        producttype,
        productcolor,
        subproduct,
        subproductcolor,
        skiptagnumber,
        companyname,
        tagdesignvalue,
      };

        
    if( tagpair?.id &&
      tagpair?.macid &&
      tagpair?.product &&
      tagpair?.productcolor &&
      tagpair?.subproduct &&
      tagpair?.subproductcolor &&
      tagpair?.producttype &&
      tagpair?.dispatchcode &&
      tagpair?.tagdesignvalue
    ){
      settotalscan((prev) => prev + 1);
      settagdata((prevTagdata) => [...prevTagdata, tagpair]);
      setdispatchModal(false);
      openscanner();

      }

    }
 
  }
  const handelskip = ()=>{
 

try {
  if(product?.slice(0, 2) === "QR" || product === "Generic"){
     
    const tagpair = {
      id: unique_id.slice(0, 8),
      tagnumber: tagno,
      product,
      producttype,
      productcolor,
      companyname,
      tagdesignvalue,
    };
  
    if( tagpair?.id &&
      tagpair?.tagnumber &&
      tagpair?.product &&
      tagpair?.productcolor &&
      tagpair?.producttype &&
      tagpair?.tagdesignvalue
     
    ){
      settotalscan((prev) => prev + 1);
      settagdata((prevTagdata) => [...prevTagdata, tagpair]);
      setdispatchModal(false);
      openscanner();

      }

  }
  else{

    const tagpair = {
      id: unique_id.slice(0, 8),
  
      tagnumber: tagno,
      macid: macid,
      product,
      producttype,
      productcolor,
      subproduct,
      subproductcolor,
      skiptagnumber,
      companyname,
      tagdesignvalue
    };

      
  if( tagpair?.id &&
    
    tagpair?.macid &&
    tagpair?.product &&
    tagpair?.productcolor &&
    tagpair?.subproduct &&
    tagpair?.subproductcolor &&
    tagpair?.producttype &&
    tagpair?.tagdesignvalue
  
  ){

    settotalscan((prev) => prev + 1);
    settagdata((prevTagdata) => [...prevTagdata, tagpair]);
    setdispatchModal(false);
    openscanner();

    }

  }

  
} catch (error) {
  console.log(error)
}
  }

  const dummyDispatch = ()=>{

    setdispatchCode("890456")
    if(product?.slice(0, 2) === "QR" || product === "Generic"){
      const tagpair = {
        id: unique_id.slice(0, 8),
        dispatchcode : "890456",
        tagnumber: tagno,
        product,
        producttype,
        productcolor,
        companyname,
        tagdesignvalue
      };
      if( tagpair?.id &&
        tagpair?.tagnumber &&
        tagpair?.product &&
        tagpair?.productcolor &&
        tagpair?.producttype &&
        tagpair?.companyname &&
        tagpair?.dispatchcode &&
        tagpair?.tagdesignvalue
      ){
        settotalscan((prev) => prev + 1);
        settagdata((prevTagdata) => [...prevTagdata, tagpair]);
        setdispatchModal(false);
        openscanner();
  
        }

    }
    else{

      const tagpair = {
        id: unique_id.slice(0, 8),
        dispatchcode : "890456",
        tagnumber: tagno,
        macid: macid,
        product,
        producttype,
        productcolor,
        subproduct,
        subproductcolor,
        skiptagnumber,
        companyname,
        tagdesignvalue
      };

        
    if( tagpair?.id &&
    
      tagpair?.macid &&
      tagpair?.product &&
      tagpair?.productcolor &&
      tagpair?.subproduct &&
      tagpair?.subproductcolor &&
      tagpair?.producttype &&
      tagpair?.dispatchcode &&
      tagpair?.tagdesignvalue
    ){
      settotalscan((prev) => prev + 1);
      settagdata((prevTagdata) => [...prevTagdata, tagpair]);
      setdispatchModal(false);
      openscanner();

      }

    }
  }
  return (
    <div className="page-content">
      {loader ? (
        <Loader />
      ) : (
        <>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <div
              style={{
                marginBottom: "2rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1>Tag pairing with mac address</h1>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ marginRight: "5rem" }}>
                <Button
                  onClick={() => {
                    setpairingmodal(true);
                    getallfields();
                    fetchallCategory();
                  }}
                  style={{ margin: "1rem" }}
                  variant="contained"
                >
                  Mac address Pairing
                </Button>
                {userrole?.length && userrole === "superadmin" && (
                  <Button
                    onClick={() => {
                      setsettingsmodal(true), getallfields();
                      fetchallCategory();
                    }}
                    style={{ margin: "1rem" }}
                    variant="outlined"
                    startIcon={<SettingsIcon />}
                  >
                    Settings
                  </Button>
                )}
              </div>
            </div>

            {userrole && userrole === "superadmin" && (
              <div style={{ margin: "1rem" }}>
                <label>Upload csv for Tag pairing with mac address</label>
                <br></br>
                <input type="file" onChange={handlecsv} />
              </div>
            )}
            <>
              <Col style={{ display: "flex" }} xl={6} sm={6}>
                <input
                  type="search"
                  id="form1"
                  placeholder="Search"
                  className="form-control"
                  name="searchkey"
                  value={searchkey}
                  onChange={(e) => handelsearchinput(e)}
                />
              </Col>
              <div style={{ marginTop: "2rem", display: "flex" }}>
                <div style={{ marginLeft: "1rem" }}>
                  <TextField
                    label="Starting Date"
                    variant="outlined"
                    type="date"
                    name="from"
                    onChange={handeldate}
                    value={datefilter?.from || ""}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      max: new Date().toISOString().split("T")[0],
                    }}
                  />
                </div>
                <div style={{ marginLeft: "1rem" }}>
                  <TextField
                    label="Ending Date"
                    variant="outlined"
                    type="date"
                    name="to"
                    onChange={handeldate}
                    value={datefilter?.to || ""}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      max: new Date().toISOString().split("T")[0],
                    }}
                  />
                </div>
                {datefilter?.from && datefilter?.to ? (
                  <div style={{ margin: "1rem" }}>
                    <Button onClick={sortdateApi} variant="outlined">
                      Search
                    </Button>
                  </div>
                ) : null}
              </div>
              <p style={{ fontWeight: "500", marginTop: "3rem" }}>
                Mac address Pairing History
              </p>
              <div
                style={{ backgroundColor: "white", marginTop: "2rem" }}
                className="table-responsive"
              >
                <BootstrapTable
                  keyField="id"
                  data={result}
                  columns={column}
                  pagination={paginationFactory()}
                />
              </div>
            </>
          </Box>
          <Dialog fullWidth={"xs"} maxWidth={"xs"} open={scaner}>
            <DialogTitle style={{ textAlign: "center", fontWeight: "bold" }}>
              Scan TagNumber
            </DialogTitle>
            <p style={{ textAlign: "center", fontWeight: "500" }}>
              Please scan your TagNumber QR{" "}
            </p>
            <small style={{ textAlign: "center" }}>Using your QR Gun</small>
            <small style={{ textAlign: "center" }}>
              {" "}
              Total no of scan : {totalscan}
            </small>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <BarcodeReader
                onScan={success}
                onError={error}
                style={{
                  width: "50%",
                  marginLeft: "4rem",
                  marginBottom: "2rem",
                }}
              />
            </div>

            {qrerror ? (
              <p
                style={{
                  textAlign: "center",
                  color: "red",
                  marginTop: "2rem",
                }}
              >
                Invalid QR
              </p>
            ) : null}
            <DialogActions>
              <Button onClick={handelclose}>Close</Button>
              {local &&                  <Button onClick={dummpy}>sample</Button>
                  }
            </DialogActions>
          </Dialog>
          <Dialog fullWidth={"xs"} maxWidth={"xs"} open={macqr}>
            <DialogTitle style={{ textAlign: "center", fontWeight: "bold" }}>
              Scan MacAddress
            </DialogTitle>
            <p style={{ textAlign: "center", fontWeight: "500" }}>
              Please scan your MacAddress QR{" "}
            </p>
            <small style={{ textAlign: "center" }}>
              {" "}
              Total no of scan : {totalscan}
            </small>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <BarcodeReader
                onScan={macscan}
                onError={error}
                style={{
                  width: "50%",
                  marginLeft: "4rem",
                  marginBottom: "2rem",
                }}
              />
            </div>
            {qrerror2 ? (
              <p
                style={{
                  textAlign: "center",
                  color: "red",
                  marginTop: "2rem",
                }}
              >
                Invalid QR
              </p>
            ) : null}
            <DialogActions>
              <Button onClick={handelclose}>Close</Button>
              { local &&
                <Button onClick={dummac}>Sample</Button>
                }
            </DialogActions>
          </Dialog>
          <Dialog fullScreen open={settingsmodal}>
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  onClick={() => setsettingsmodal(false)}
                  edge="start"
                  color="inherit"
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                >
                  Settings
                </Typography>
                <Button
                  onClick={() => setsettingsmodal(false)}
                  autoFocus
                  color="inherit"
                >
                  close
                </Button>
              </Toolbar>
            </AppBar>
            <DialogContent dividers={scroll === "paper"}>
              <Box
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p style={{ fontWeight: "500", marginTop: "3rem" }}>
                    Customer Name
                  </p>
                  <div
                    style={{
                      backgroundColor: "white",
                      marginTop: "2rem",
                      width: "50%",
                    }}
                    className="table-responsive"
                  >
                    <Button
                      style={{ margin: "1rem" }}
                      variant="contained"
                      onClick={() => handeladdmodal("Customer Name")}
                    >
                      Add Customer Name
                    </Button>
                    <BootstrapTable
                      keyField="_id"
                      data={customername}
                      columns={column5}
                      pagination={paginationFactory()}
                    />
                  </div>
                  <p style={{ fontWeight: "500", marginTop: "3rem" }}>
                    Tag Design
                  </p>
                  <div
                    style={{
                      backgroundColor: "white",
                      marginTop: "2rem",
                      width: "50%",
                    }}
                    className="table-responsive"
                  >
                    <Button
                      style={{ margin: "1rem" }}
                      variant="contained"
                      onClick={() => handeladdmodal("Tag Design")}
                    >
                      Add Tag Design
                    </Button>
                    <BootstrapTable
                      keyField="_id"
                      data={tagdesign}
                      columns={column6}
                      pagination={paginationFactory()}
                    />
                  </div>
                  <p style={{ fontWeight: "500", marginTop: "3rem" }}>
                    Product
                  </p>
                  <div
                    style={{
                      backgroundColor: "white",
                      marginTop: "2rem",
                      width: "50%",
                    }}
                    className="table-responsive"
                  >
                    <Button
                      style={{ margin: "1rem" }}
                      variant="contained"
                      onClick={() => handeladdmodal("Product")}
                    >
                      Add Product
                    </Button>
                    <BootstrapTable
                      keyField="_id"
                      data={producttypefield}
                      columns={column1}
                      pagination={paginationFactory()}
                    />
                  </div>
                  <p style={{ fontWeight: "500", marginTop: "3rem" }}>
                    Product Colour
                  </p>
                  <div
                    style={{
                      backgroundColor: "white",
                      marginTop: "2rem",
                      width: "50%",
                    }}
                    className="table-responsive"
                  >
                    <Button
                      style={{ margin: "1rem" }}
                      variant="contained"
                      onClick={() => handeladdmodal("Product Colour")}
                    >
                      Add Product Colour
                    </Button>
                    <BootstrapTable
                      keyField="_id"
                      data={producttypecolor}
                      columns={column2}
                      pagination={paginationFactory()}
                    />
                  </div>
                  <p style={{ fontWeight: "500", marginTop: "3rem" }}>
                    Embeded Hardware
                  </p>
                  <div
                    style={{
                      backgroundColor: "white",
                      marginTop: "2rem",
                      width: "50%",
                    }}
                    className="table-responsive"
                  >
                    <Button
                      style={{ margin: "1rem" }}
                      variant="contained"
                      onClick={() => handeladdmodal("Embeded Hardware")}
                    >
                      Add Embeded Hardware
                    </Button>
                    <BootstrapTable
                      keyField="_id"
                      data={subproducttype}
                      columns={column3}
                      pagination={paginationFactory()}
                    />
                  </div>
                  <p style={{ fontWeight: "500", marginTop: "3rem" }}>
                    Hardware Colour
                  </p>
                  <div
                    style={{
                      backgroundColor: "white",
                      marginTop: "2rem",
                      width: "50%",
                    }}
                    className="table-responsive"
                  >
                    <Button
                      style={{ margin: "1rem" }}
                      variant="contained"
                      onClick={() => handeladdmodal("Hardware Colour")}
                    >
                      Add Hardware Color
                    </Button>
                    <BootstrapTable
                      keyField="_id"
                      data={subproducttypecolor}
                      columns={column4}
                      pagination={paginationFactory()}
                    />
                  </div>
                </div>
              </Box>
            </DialogContent>

            <Dialog fullWidth={"md"} open={addmodal?.open}>
              <DialogTitle>{`Add ${addmodal?.type}`}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <form
                    style={{ padding: "1rem" }}
                    onSubmit={(e) =>
                      handelfieldsubmit(e, addmodal?.type, "add")
                    }
                  >
                    <div>
                      <TextField
                        name={addmodal?.type}
                        onChange={handelchange}
                        fullWidth
                        label={`${addmodal?.type}`}
                        id="fullWidth"
                      />
                    </div>
                    {addmodal?.type == "Product" && (
                      <>
                        <div style={{ marginTop: "1rem" }}>
                          <TextField
                            disabled={
                              field[addmodal?.type]?.length ? false : true
                            }
                            fullWidth
                            select
                            id="outlined-multiline-flexible1"
                            label="Product Category"
                            variant="outlined"
                            value={dependency}
                            onChange={adddependency}
                          >
                            {allcatg?.map((e) => {
                              return (
                                <MenuItem
                                  key={e?._id}
                                  value={e?.product_category}
                                >
                                  {e?.product_category === "Dolphin Bag"
                                    ? "Dolphin Bag Tracker"
                                    : e?.product_category}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </div>
                        <div style={{ marginTop: "1rem" }}>
                          <p style={{ fontSize: "small" }}>
                            Products Available Only for Selected Category
                          </p>
                        </div>
                        <div>
                          {[...categories].map((e, index) => {
                            return (
                              <>
                                <Chip
                                  key={index}
                                  onDelete={() => deletecatg(e)}
                                  label={e}
                                  variant="outlined"
                                />
                              </>
                            );
                          })}
                        </div>
                      </>
                    )}

                    <div style={{ margin: "1rem" }}>
                      <Button
                        onClick={() => handelcancel("add")}
                        style={{ margin: "1rem" }}
                        variant="contained"
                        color="error"
                      >
                        Cancel
                      </Button>
                      {addmodal?.type === "Product" ? (
                        <Button
                          disabled={
                            field[addmodal?.type]?.length &&
                            categories.size !== 0
                              ? false
                              : true
                          }
                          type="submit"
                          style={{ margin: "1rem" }}
                          variant="contained"
                          color="success"
                        >
                          Add
                        </Button>
                      ) : (
                        <Button
                          disabled={
                            field[addmodal?.type]?.length ? false : true
                          }
                          type="submit"
                          style={{ margin: "1rem" }}
                          variant="contained"
                          color="success"
                        >
                          Add
                        </Button>
                      )}
                    </div>
                  </form>
                </DialogContentText>
              </DialogContent>
            </Dialog>

            <Dialog fullWidth={"md"} open={editmodal?.open}>
              <DialogTitle>{`Edit ${editmodal?.type}`}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <form
                    style={{ padding: "1rem" }}
                    onSubmit={(e) =>
                      handelfieldsubmit(
                        e,
                        editmodal?.type,
                        "edit",
                        editmodal?._id,
                        editmodal?.category_map
                      )
                    }
                  >
                    <div>
                      <TextField
                        name={editmodal?.type}
                        value={field[editmodal?.type]}
                        onChange={handelchange}
                        fullWidth
                        label={`${editmodal?.type}`}
                        id="fullWidth"
                      />
                    </div>
                    {editmodal?.type == "Product" && (
                      <>
                        <div style={{ marginTop: "1rem" }}>
                          <TextField
                            disabled={
                              field[editmodal?.type]?.length ? false : true
                            }
                            fullWidth
                            select
                            id="outlined-multiline-flexible1"
                            label="Product Category"
                            variant="outlined"
                            value={editdependency}
                            onChange={addeditdependency}
                          >
                            {allcatg?.map((e) => {
                              return (
                                <MenuItem
                                  key={e?._id}
                                  value={e?.product_category}
                                >
                                  {e?.product_category === "Dolphin Bag"
                                    ? "Dolphin Bag Tracker"
                                    : e?.product_category}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </div>
                        <div style={{ marginTop: "1rem" }}>
                          <p style={{ fontSize: "small" }}>
                            Products Available Only for Selected Category
                          </p>
                        </div>
                        <div>
                          {[...editmodal?.category_map].map((e, index) => {
                            return (
                              <>
                                <Chip
                                  key={index}
                                  onDelete={() => removeCatg(e)}
                                  label={e}
                                  variant="outlined"
                                />
                              </>
                            );
                          })}
                        </div>
                      </>
                    )}

                    <div style={{ margin: "1rem" }}>
                      <Button
                        onClick={() => handelcancel("edit")}
                        style={{ margin: "1rem" }}
                        variant="contained"
                        color="error"
                      >
                        Cancel
                      </Button>
                      {editmodal?.type === "Product" ? (
                        <Button
                          disabled={
                            field[editmodal?.type]?.length &&
                            editmodal?.category_map.size !== 0
                              ? false
                              : true
                          }
                          type="submit"
                          style={{ margin: "1rem" }}
                          variant="contained"
                          color="success"
                        >
                          Update
                        </Button>
                      ) : (
                        <Button
                          disabled={
                            field[editmodal?.type]?.length ? false : true
                          }
                          type="submit"
                          style={{ margin: "1rem" }}
                          variant="contained"
                          color="success"
                        >
                          Update
                        </Button>
                      )}
                    </div>
                  </form>
                </DialogContentText>
              </DialogContent>
            </Dialog>
            <Dialog open={deletemodal?.open}>
              <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete this field..?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    handelcancel("delete");
                  }}
                >
                  Disagree
                </Button>
                <Button
                  onClick={(e) =>
                    handelfieldsubmit(
                      e,
                      deletemodal?.type,
                      "delete",
                      deletemodal?._id
                    )
                  }
                >
                  Agree
                </Button>
              </DialogActions>
            </Dialog>
          </Dialog>
          <Dialog fullScreen open={pairingmodal}>
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handelreset}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                >
                  Data Pairing
                </Typography>
                <Button autoFocus color="inherit" onClick={handelreset}>
                  Close
                </Button>
              </Toolbar>
            </AppBar>
            <DialogContent dividers={scroll === "paper"}>
              <Box
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div>
                    <p style={{ fontWeight: "600" }}>
                      Total no of scan : {totalscan}
                    </p>
                  </div>
                  <div>
                    <TextField
                      select
                      id="outlined-multiline-flexible1"
                      label="Product Category"
                      variant="outlined"
                      value={product}
                      onChange={changecategory}
                    >
                      {allcatg?.map((e) => {
                        return (
                          <MenuItem key={e?._id} value={e?.product_category}>
                            {e?.product_category === "Dolphin Bag"
                              ? "Dolphin Bag Tracker"
                              : e?.product_category}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </div>
                  <div>
                    {product && (
                      <>
                        <TextField
                          select
                          id="outlined-multiline-flexible1"
                          label="Product"
                          variant="outlined"
                          value={producttype}
                          onChange={(e) => setproducttype(e.target.value)}
                        >
                          {producttypefield?.map((e) => {
                            return (
                              e?.category_map?.includes(product) && (
                                <MenuItem key={e?._id} value={e?.value}>
                                  {e?.value}
                                </MenuItem>
                              )
                            );
                          })}
                        </TextField>
                        <TextField
                          id="outlined-multiline-flexible2"
                          select
                          label="Product Color"
                          variant="outlined"
                          value={productcolor}
                          onChange={(e) => setcolour(e.target.value)}
                        >
                          {producttypecolor?.map((e) => {
                            return (
                              <MenuItem key={e?._id} value={e?.value}>
                                {e?.value}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                        <TextField
                          id="outlined-multiline-flexible2"
                          select
                          label="Tag Design"
                          variant="outlined"
                          value={tagdesignvalue}
                          onChange={(e) => settagdesignvalue(e.target.value)}
                        >
                          {tagdesign?.map((e) => {
                            return (
                              <MenuItem key={e?._id} value={e?.design_name}>
                                {e?.design_name}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </>
                    )}
                  </div>
                  <div>
                    {product &&
                      product?.slice(0, 2) !== "QR" &&
                      product !== "Generic" && (
                        <>
                          <TextField
                            select
                            id="outlined-multiline-flexible1"
                            label="Embeded Hardware"
                            variant="outlined"
                            value={subproduct}
                            onChange={(e) => setsubproduct(e.target.value)}
                          >
                            {subproducttype?.map((e) => {
                              return (
                                <MenuItem key={e?._id} value={e?.value}>
                                  {e?.value}
                                </MenuItem>
                              );
                            })}
                          </TextField>

                          <TextField
                            id="outlined-multiline-flexible2"
                            select
                            label="Hardware Color"
                            variant="outlined"
                            value={subproductcolor}
                            onChange={(e) => setsubproductcolor(e.target.value)}
                          >
                            {subproducttypecolor?.map((e) => {
                              return (
                                <MenuItem key={e?._id} value={e?.value}>
                                  {e?.value}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </>
                      )}
                    {product && (
                      <TextField
                        id="outlined-multiline-flexible2"
                        select
                        label="Customer Name"
                        variant="outlined"
                        value={companyname}
                        onChange={(e) => setcompanyname(e.target.value)}
                      >
                        {customername?.map((e) => {
                          return (
                            <MenuItem key={e?._id} value={e?.organisation_name}>
                              {e?.organisation_name}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    )}
                  </div>
                  <div></div>

                  {companyname?.length && tagdesignvalue?.length &&
                  (product?.slice(0, 2) === "QR" || product === "Generic") ? (
                    <>
                      <div style={{ margin: "1rem" }}>
                        <Button onClick={openscanner} variant="contained">
                          {" "}
                          Start Scaning QR
                        </Button>
                      </div>

                      <TextField
                        label="Owner ID"
                        variant="outlined"
                        value={tagno}
                      />
                          <TextField
                          label="Dispatch Code"
                          variant="outlined"
                          value={dispatchCode}
                        />
                    </>
                  ) : product?.slice(0, 2) !== "QR" &&
                    product !== "Generic" &&
                    producttype.length &&
                    product.length &&
                    productcolor.length &&
                    subproduct.length &&
                    subproductcolor.length && 
                    tagdesignvalue
                     ? (
                    <>
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          How pairing need to be done
                        </FormLabel>
                        <RadioGroup
                          onChange={handelradiobutton}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="reportlost_type"
                          value={skiptagnumber}
                        >
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="QR & Mac ID Pairing"
                          />
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Only Mac ID"
                          />
                        </RadioGroup>
                      </FormControl>
                      <div style={{ margin: "1rem" }}>
                        <Button
                          disabled={
                            !product.length || !productcolor.length
                              ? true
                              : false
                          }
                          onClick={openscanner}
                          variant="contained"
                        >
                          {" "}
                          Start Scaning QR
                        </Button>
                      </div>
                      <div>
                        {!skiptagnumber && (
                          <TextField
                            label="Owner ID"
                            variant="outlined"
                            value={tagno}
                          />
                        )}
                        <TextField
                          label="Mac ID"
                          variant="outlined"
                          value={macid}
                        />
                          <TextField
                          label="Dispatch Code"
                          variant="outlined"
                          value={dispatchCode}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
                <div style={{ marginTop: "2rem" }}>
                  {tagdata.length ? (
                    <>
                      <p style={{ fontWeight: "500" }}></p>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell>Tagnumber</TableCell>
                              <TableCell>Macaddress</TableCell>
                              <TableCell>Company Name</TableCell>
                              <TableCell>Dispatch Code</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {tagdata.map((e, index) => {
                              return (
                                <TableRow key={e.id}>
                                  <TableCell>{index + 1}</TableCell>
                                  <TableCell>{e.tagnumber}</TableCell>
                                  <TableCell>{e.macid}</TableCell>
                                  <TableCell>{e?.companyname}</TableCell>
                                  <TableCell>{e?.dispatchcode}</TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Button
                        onClick={handelsubmit}
                        style={{ margin: "2rem", alignItems: "center" }}
                        variant="contained"
                      >
                        Submit
                      </Button>
                    </>
                  ) : null}
                </div>
              </Box>
            </DialogContent>
          </Dialog>

          <Dialog fullWidth={"xs"} maxWidth={"xs"} open={dispatchModal}>
            <DialogTitle style={{ textAlign: "center", fontWeight: "bold" }}>
              Scan Dispatchcode
            </DialogTitle>
            <p style={{ textAlign: "center", fontWeight: "500" }}>
              Please scan your Dispatch Barcode{" "}
            </p>
            <small style={{ textAlign: "center" }}>
              Using your barcode reader
            </small>
            <BarcodeReader
            minLength = {3}
              onScan={dispatchScan}
              style={{ width: "50%", marginLeft: "4rem", marginBottom: "2rem" }}
            />

         <DialogActions>
              <Button onClick={handelskip}>Skip</Button>
              {
                local &&
                   <Button onClick={dummyDispatch}>Sample</Button> 
              }
            </DialogActions>
          </Dialog>
        </>
      )}
    </div>
  );
}

export default Macpairing;
